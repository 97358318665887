import AdminHeaderTabs from "../../../Common/Header/Tabs";
import { ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";

const MyAccountTabs = () => {
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");

  let path = window.location.pathname.split("/")[2];
  
  if (window.location.pathname.split("/")[3] != null) {
    path = path + "/" + window.location.pathname.split("/")[3];
  }
  
  const cleanActives = () => {
    setActive1("");
    setActive2("");
  }

  useEffect(() => {
    switch (path) {
      case "manage":
        cleanActives();
        setActive1("active");
        break;
      case "manage/school":
        cleanActives();
        setActive2("active");
        break;
    }
  }, [path]);

  return (
    <div className="navbar navbar-blue justify-content-start">
      <ListGroup as="ul" className="list-group list-group-horizontal">
        <AdminHeaderTabs
          to={"/adm/manage"}
          classes={`first ${active1}`}
          text={"Account Management"}
        />
        <AdminHeaderTabs
          to={"/adm/manage/school"}
          classes={active2}
          text={"School Management"}
        />
      </ListGroup>
    </div>
  );
};

export default MyAccountTabs;
