import { Formik, Form, Field, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import { editSubscription } from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";

let controller = new AbortController();

const EditSubscriptionModal = (props: {
  onClick: () => void;
  changeModal: (modal: any) => void;
  onSubmit: any;
  subscriptionId: any;
  schoolId: any;
  className: string;
  teacherId: number;
}) => {
  const userContext = useContext(UserContext);
  const [errorUpdating, setErrorUpdating] = useState(false);
  const [disableButton, setButtonDisabled] = useState(false);

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  let teachers: any = userContext.teachers.data.teachers;

  const renderTeachers = () => {
    return teachers.map((teacher: any, index: number) => {
      if (userContext.teachers.data.migration_requests.includes(teacher.userId)) {
        return <></>
      } else {
        return (
          <option
            value={teacher.edu.eduUserId}
            key={index}
          >{`${teacher.edu.firstName} ${teacher.edu.lastName}`}</option>
        );
      }
    });
  };

  const changeModal = () => {
    props.onClick();
    props.changeModal("add-teacher-modal");
  };

  const handleSubmit = (values: FormikValues) => {
    setButtonDisabled(true);
    userContext.setFormProgress(50);
    let data;

    if (values.teacherId === "unassigned") {
      data = {
        className: values.className,
        teacherId: null,
      };
    } else {
      data = values;
      data.teacherId = Number(data.teacherId);
    }

    editSubscription(
      requestConfig,
      props.schoolId,
      props.subscriptionId,
      data
    ).then((response) => {
      if (response.status) {
        props.onSubmit();
      } else {
        setButtonDisabled(false);
        setErrorUpdating(true);
      }
      userContext.setFormProgress(100);
    });
  };

  return (
    <div className="modal-body-wrapper manage-subscriptions">
      <legend className="subtitle-wrapper">
        <span>Subscription Info</span>
        <div className="bar"></div>
      </legend>
      <Formik
        initialValues={{
          className: props.className,
          teacherId: props.teacherId !== 0 ? props.teacherId : "unassigned",
        }}
        onSubmit={handleSubmit}
      >
        <Form className="modal-form">
          {errorUpdating && (
            <div className="my-2">
              <TextError
                wrapper="div"
                text="There was an error, We were not able to update the class, please try again"
              />
            </div>
          )}
          <div className="form-item name">
            <label htmlFor="className">Class Name</label>
            <Field
              type="text"
              name="className"
              className="form-input class-name"
            ></Field>
          </div>

          <div className="form-item teacher-name-form">
            <label htmlFor="teacher">Teacher</label>
            <Field
              as="select"
              type="text"
              name="teacherId"
              className="form-input teacher-name"
              default={props.teacherId}
            >
              <option value="unassigned" key={0}>
                Unassigned
              </option>
              {renderTeachers()}
            </Field>
            <button
              type="button"
              className="btn-modal btn-brandy-punch"
              onClick={changeModal}
              disabled={disableButton}
            >
              +
            </button>
          </div>
          <div className="buttons-wrapper mt-4">
            <button
              type="button"
              className="btn-modal btn-mojo-modal"
              onClick={() => props.onClick()}
              disabled={disableButton}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-modal btn-brandy-punch btn-spinner-indicator"
              disabled={disableButton}
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default EditSubscriptionModal;
