import AdminHeaderTabs from "../../Common/Header/Tabs";
import { ListGroup } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../context/user";
import CleverContext from '../../../../context/clever'
import { isCleverTheme } from "../../../../utils/helpers";

const AdminHeaderSections = () => {
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");

  let path = window.location.pathname.split("/")[2];

  if (path === "school" && window.location.pathname.split("/")[3] != null) {
    path = path + "/" + window.location.pathname.split("/")[3];
  }

  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  let tabs, justify;

  if (isCleverTheme(userContext, cleverContext)) {
    justify = "justify-content-end";
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal">
        <AdminHeaderTabs
          to={"/adm/dashboard"}
          classes={`first ${active1}`}
          text={"My Home"}
        />
        <AdminHeaderTabs
          to={"/adm/school"}
          classes={active3}
          text={`${isCleverTheme(userContext, cleverContext) ? 'View' : 'Manage'} My Subscriptions`}
        />
        <AdminHeaderTabs
          to={"/adm/reports"}
          classes={active4}
          text={"Reports"}
        />
      </ListGroup>
    );
  } else if (userContext.userWithSubscription) {
    justify = "justify-content-end";
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal">
        <AdminHeaderTabs
          to={"/adm/dashboard"}
          classes={`first ${active1}`}
          text={"My Home"}
        />
        <AdminHeaderTabs
          to={"/adm/school/teacher"}
          classes={active2}
          text={`${isCleverTheme(userContext, cleverContext) ? 'View' : 'Manage'} My Teachers`}
        />
        <AdminHeaderTabs
          to={"/adm/school"}
          classes={active3}
          text={"Manage My Subscriptions"}
        />
        <AdminHeaderTabs
          to={"/adm/reports"}
          classes={active4}
          text={"Reports"}
        />
      </ListGroup>
    );
  } else {
    justify = "ps-4";
    tabs = <ListGroup as="ul" className="list-group list-group-horizontal no-active-subscriptions">
        <AdminHeaderTabs
          to={"/adm/dashboard"}
          classes={`first ${active1}`}
          text={"My Home"}
        />
        <AdminHeaderTabs
          to={"/adm/school"}
          classes={active3}
          text={"Manage My Subscriptions"}
        />
      </ListGroup>
  }

  const cleanActives = () => {
    setActive1("");
    setActive2("");
    setActive3("");
    setActive4("");
  };

  useEffect(() => {
    switch (path) {
      case "dashboard":
        cleanActives();
        setActive1("active");
        break;
      case "school/teacher":
        cleanActives();
        setActive2("active");
        break;
      case "school":
        cleanActives();
        setActive3("active");
        break;
      case "reports":
        cleanActives();
        setActive4("active");
        break;
      default:
        cleanActives();
        break;
    }
  }, [path]);

  return (
    <div className={`navbar navbar-green ${isCleverTheme(userContext, cleverContext) ? 'navbar-sky' : 'navbar-green'} ${justify}`}>
      {tabs}
    </div>
  );
};

export default AdminHeaderSections;
