import TableNoResult from "@components/Dashboard/Common/TableNoResult";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  getDistrictReportsForClever,
  getSummaryOfCleverDistrict,
} from "../../../../../api/rifService";
import CleverContext from "../../../../../context/clever";
import UserContext from "../../../../../context/user";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import {
  DEFAULT_DISTRICT_ADMIN_SKY_THEME,
  PER_PAGE_REPORTS_FOR_CLEVER,
  SKYBRARY_CLEVER_TOKEN,
} from "@utils/constants";
import { isCleverTheme } from "../../../../../utils/helpers";
import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import PaginatedTable from "../../../Common/PaginatedTable";
import Table from "../../../Common/Table";
import PopUpModal from "../../../Teacher/Modals/PopUpModal";
import Cookies from "universal-cookie";

type TableRow = Record<string, any>;

const cookies = new Cookies();

const AdminReports = () => {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  let cleverToken = cookies.get(SKYBRARY_CLEVER_TOKEN);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
      ...(cleverToken && { cleveraccesstoken: cleverToken }),
    },
  };
  const collapseTextField = ["admin", "reports"];
  let mappedTableData: any[] = [];
  const cleverTheme = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );
  const [totalBooksRead, setTotalBooksRead] = useState(0);
  const [totalTimeReading, setTotalTimeReading] = useState(0);
  const [totalVideosWathched, setTotalVideosWathched] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [dataTable, setDataTable] = useState<TableRow[]>([]);
  const data = React.useMemo(() => dataTable, [dataTable]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const items = [
    {
      step: 1,
      text: "Admin Dashboard",
      link: "/adm/dashboard",
    },
    {
      step: 2,
      text: "Reports",
      link: "/adm/reports",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Class Name",
        accessor: "col1",
        width: 356.63,
        customStyles: {
          textAlign: "left",
          paddingLeft: "0.9375rem"
        }
      },
      {
        Header: "Books Read",
        accessor: "col2",
        width: 121.69,
      },
      {
        Header: "Time Reading (mins.)",
        accessor: "col3",
        width: 121.69,
      },
      {
        Header: "Videos Watched",
        accessor: "col4",
        width: 121.69,
      },
      {
        Header: "",
        accessor: "col5",
        width: 140.31,
      },
    ],
    [],
  );

  const cleverColumns = React.useMemo(
    () => [
      {
        Header: "Class Name",
        accessor: "col1",
        width: 356,
      },
      {
        Header: "School",
        accessor: "col2",
        width: 121.69,
      },
      {
        Header: "Grade",
        accessor: "col3",
        width: 100,
      },
      {
        Header: "Teacher",
        accessor: "col4",
        width: 281,
      },
      {
        Header: "Books Read",
        accessor: "col5",
        width: 141,
      },
      {
        Header: "Time Reading (minutes)",
        accessor: "col6",
        width: 356,
      },
      {
        Header: "Videos Watched",
        accessor: "col7",
        width: 95,
      },
      {
        Header: "",
        accessor: "col8",
        width: 95,
      },
    ],
    [],
  );

  const handlePage = async (actualPage: number) => {
    setActualPage(actualPage);
    userContext.setFormProgress(50);
    const reportResponse = await getDistrictReportsForClever(
      requestConfig,
      null,
      PER_PAGE_REPORTS_FOR_CLEVER,
      actualPage,
    );
    if (!reportResponse.error) {
      userContext.saveSchoolReports(reportResponse);
    }

    setIsTableLoading(false);
    setLastPage(reportResponse.pagination.lastPage);
  };

  const CleverTable = () => {
    return data && data.length > 0 ? (
      <PaginatedTable
        data={data}
        columns={cleverColumns}
        modalClass="report-table"
        sortArrows={false}
        page={actualPage}
        handleChangePage={handlePage}
        lastPage={lastPage}
        isTableLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
      />
    ) : (
      <TableNoResult
        data={data}
        columns={cleverColumns}
        modalClass="table-body-no-result-report"
      />
    );
  };

  const mapTableDataForClever = (allReports: any) => {
    if (totalBooksRead === 0) {
      getSummaryOfCleverDistrict(requestConfig, null).then((summaryReport) => {
        let timeRead = summaryReport.data.booksHistory.totalTimeReading;
        setTotalBooksRead(
          summaryReport.data.booksHistory.amountOfUniqueBooksRead,
        );
        setTotalTimeReading(timeRead);
        setTotalVideosWathched(
          summaryReport.data.videosHistory.amountOfUniqueVideosWatched,
        );
      });
    }
    mappedTableData = allReports.map((report: any) => {
      let viewReportDetailClasses = "btn-table-moderate-green";
      if (!cleverTheme) {
        viewReportDetailClasses =
          report.booksRead > 0
            ? "btn-table-brandy-punch"
            : "btn-table-di-serria";
      }
      let colWrapperClass = "report-cell";
      let reportStar = <></>;
      if (report.booksRead > 0) {
        colWrapperClass += " has-read";
        reportStar = <div className="report-star" />;
        if (cleverTheme) {
          reportStar = (
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0L15.3064 8.2918H24.3882L17.0409 13.4164L19.8473 21.7082L12.5 16.5836L5.15268 21.7082L7.95911 13.4164L0.611794 8.2918H9.69357L12.5 0Z"
                fill="#800484"
              />
            </svg>
          );
        }
      }

      return {
        col1: (
          <div className={colWrapperClass}>
            <span>{report.classroomName}</span>
            {reportStar}
          </div>
        ),
        col2: (
          <div className={colWrapperClass}>
            <span>{report.schoolName}</span>
          </div>
        ),
        col3: (
          <div className={colWrapperClass}>
            <span>{report.grade}</span>
          </div>
        ),
        col4: (
          <div className={colWrapperClass}>
            <span>{report.teacherName}</span>
          </div>
        ),
        col5: (
          <div className={colWrapperClass}>
            <span>{report.booksRead}</span>
          </div>
        ),
        col6: (
          <div className={colWrapperClass}>
            <span>{Math.ceil(report.totalReadingTime / 60)}</span>
          </div>
        ),
        col7: (
          <div className={colWrapperClass}>
            <span>{report.videosWatched}</span>
          </div>
        ),
        col8: (
          <Link
            to={`/adm/reports/classroom/${report.subId}`}
            className={`btn ${viewReportDetailClasses} reports-view-button`}
          >
            View
          </Link>
        ),
      };
    });

    mappedTableData.push({
      col1: "Totals",
      col5: totalBooksRead,
      col6: Math.ceil(totalTimeReading / 60),
      col7: totalVideosWathched,
      col8: "",
    });

    setDataTable(mappedTableData);
  };

  const mapTableData = (allReports: any) => {
    let tempTotalBooksRead = 0;
    let tempTotalTimeReading = 0;
    let tempTotalVideosWatched = 0;
    mappedTableData = allReports.map((report: any) => {
      tempTotalBooksRead += report.booksRead;
      tempTotalTimeReading += report.totalReadingTime;
      tempTotalVideosWatched += report.videosWatched;
      return {
        col1: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.classroomName}
            {report.booksRead > 0 && <div className="report-star" />}
          </div>
        ),
        col2: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.booksRead}
          </div>
        ),
        col3: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.totalReadingTime}
          </div>
        ),
        col4: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.videosWatched}
          </div>
        ),
        col5: (
          <Link
            to={`/adm/reports/classroom/${report.subId}`}
            className={`btn ${
              report.booksRead > 0
                ? "btn-table-brandy-punch"
                : "btn-table-di-serria"
            } reports-view-button`}
          >
            View
          </Link>
        ),
      };
    });
    mappedTableData.push({
      col1: "Totals",
      col2: tempTotalBooksRead,
      col3: Math.ceil(tempTotalTimeReading / 60),
      col4: tempTotalVideosWatched,
      col5: "",
    });

    setDataTable(mappedTableData);
  };

  useEffect(() => {
    if (cleverTheme) {
      const fetchInitialData = async () => {
        const reportResponse = await getDistrictReportsForClever(
          requestConfig,
          null,
          PER_PAGE_REPORTS_FOR_CLEVER,
          actualPage,
        );
        if (!reportResponse.error) {
          userContext.saveSchoolReports(reportResponse);
          setLastPage(reportResponse.pagination.lastPage);
        }
      };

      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    document.title = "Reports | Reading Is Fundamental";
    if (
      cleverContext.isCleverUser() &&
      userContext.schoolReports &&
      userContext.subscriptions
    ) {
      mapTableDataForClever(userContext.schoolReports);
    } else if (userContext.schoolReports) {
      mapTableData(userContext.schoolReports);
    }
  }, [
    userContext.schoolReports,
    totalBooksRead,
    totalTimeReading,
    totalVideosWathched,
  ]);

  return (
    <Dashboard rol="admin">
      <div className="reports district-reports-wrapper">
        {!cleverTheme ? (
          <>
            <Steeper steps={items} activeStep={2} steeperType="dashboard" />
            <CollapseText
              dashboardField={collapseTextField}
              bodyImg=""
              title="Reports"
            />
            <div className="row main-header user-classroster admin-table p-0">
              <div>All classes</div>
            </div>
          </>
        ) : (
          <>
            <PopUpModal
              title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.reports.title}
              body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.reports.body}
              modalClass={"district-report-modal"}
            />
            <h1 className={"text-center main-title"}>Reports</h1>
          </>
        )}

        {cleverTheme ? (
          CleverTable()
        ) : (
          <Table data={data} columns={columns} modalClass={"report-table"} />
        )}
      </div>
    </Dashboard>
  );
};

export default AdminReports;
