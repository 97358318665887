import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import TextError from "../../../TextError";
import * as Yup from "yup";
import UserContext from "../../../../context/user";
import {
  editPassword,
  editUserInfo,
  getSchoolReports,
  getSchoolSubscriptions,
  getTeachers,
  userSignIn,
  getParentRosters
} from "../../../../api/rifService";
import { sha256 } from "js-sha256";
import DashboardContext from "../../../../context/dashboardInfo";
import NavBar from "../../../NavBar";
import ButtonMenu from "../../../ButtonMenu";
import Footer from "../../../Footer/Footer";
import { useSpinner } from "@providers/SpinnerProvider";

const FormSignIn = () => {
  const userContext: any = useContext(UserContext);
  const dashboardContext: any = useContext(DashboardContext);
  const location = useLocation();

  let userEmail = "";
  let userId: number;
  let activateTeacher = false;
  let activateTeacherParent = false;
  let activateParent = false;

  const navigate = useNavigate();

  let requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  if (location.state !== null) {
    if (location.state.activateTeacher || location.state.activateParent) {
      userEmail = location.state.data.emailAddress;
      activateTeacher = location.state.activateTeacher;
      activateParent = location.state.activateParent;
    } else if (location.state.activateTeacherParent) {
      userEmail = location.state.data.emailAddress;
      activateTeacherParent = true;
    } else {
      userId = userContext.userData.userId;
      userEmail = userContext.userData.emailAddress;
      requestConfig.headers.Authorization = `${location.state?.access_token}`;
    }
  } else {
    userId = userContext.userData.userId;
    userEmail = userContext.userData.emailAddress;
  }

  const validate = Yup.object({
    password: Yup.string()
      .required("Please enter a password.")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase character."
      )
      .min(8, "Password must be at least 8 characters in length."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Re-enter Password field is required."),
    agreement: Yup.boolean().oneOf(
      [true],
      "Please agree to the Terms of Use below"
    ),
  });

  const validateParent = Yup.object({
    email: Yup.string()
      .required("Set your account email field is required.")
      .notOneOf([userEmail, userEmail.replace('parent-change-me-', '')], 'That email is being used on another Reading Is Fundamental Account'),
    confirmEmail: Yup.string()
      .required("Repeat your email field is required."),
    password: Yup.string()
      .required("Please enter a password.")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase character."
      )
      .min(8, "Password must be at least 8 characters in length."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Re-enter Password field is required."),
    agreement: Yup.boolean().oneOf(
      [true],
      "Please agree to the Terms of Use below"
    ),
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [status, setStatus] = useState(false);
  const { showSpinner, hideSpinner } = useSpinner();

  const handleSubmit = async (values: FormikValues) => {
    setButtonDisabled(true);
    showSpinner();
    userContext.saveIsLogging(true);

    let passwordEncrypted = sha256(
      `${userEmail}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`
    );

    userContext.setFormProgress(20);
    let response;

    if (activateTeacher || activateParent) {
      response = await editPassword(
        userEmail,
        location.state?.token,
        passwordEncrypted
        );
    } else if (activateTeacherParent) {
        response = await editPassword(
          userEmail,
          location.state?.token,
          passwordEncrypted
        );
        if (response) {
          let userData = await userSignIn(userEmail, passwordEncrypted)
          if (userData.status) {
            passwordEncrypted = sha256(
              `${values.email}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`
            );
            let config = {
              headers: {
                Authorization: `Bearer ${userData.data.auth.accessToken}`,
              },
            };
            let data = {
              firstName: userData.data.userResponse.data.edu.user.firstName,
              lastName: userData.data.userResponse.data.edu.user.lastName,
              emailAddress: values.email,
              password: passwordEncrypted,
              acceptTOS: values.agreement
            };
            userId = userData.data.userResponse.data.userId;
            response = await editUserInfo(config, data, userId);
            userEmail = values.email
          }
        }
    } else {
        let data = {
          firstName: userContext.userData.edu.user.firstName,
          lastName: userContext.userData.edu.user.lastName,
          email: userEmail,
          password: passwordEncrypted,
          acceptTOS: values.agreement
        };
        response = await editUserInfo(requestConfig, data, userId);
    }

    if (response.status || response) {
      userContext.logout();
      userContext.setFormProgress(40);

      userSignIn(userEmail, passwordEncrypted).then((data) => {
        if (data.status) {
          userContext.login(data.data);

          userContext.setFormProgress(60);

          const token = data.data.auth.accessToken;
          const schoolId = data.data.userResponse.data.edu.user.rrkSchoolId;

          const requestConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          userContext.setFormProgress(70);

          if (data.data.userResponse.data.edu.user.type === "admin") {
            getSchoolSubscriptions(requestConfig, schoolId, null).then(
              (schoolSubscription) => {
                console.log('get sub 3')
                userContext.saveSubscriptions(schoolSubscription, data.data.userResponse.data.edu.user);
              }
            );
          } else if (data.data.userResponse.data.edu.user.type === "teacher") {
            getSchoolSubscriptions(
              requestConfig,
              schoolId,
              data.data.userResponse.data.edu.user.rrkEduUserId
            ).then((schoolSubscription) => {
              console.log('get sub 4')
              userContext.saveSubscriptions(schoolSubscription, data.data.userResponse.data.edu.user);
            });
          } else {
            getParentRosters(data.data.userResponse.data.edu.user.rrkEduUserId, requestConfig).then(
              (parentReport) => {
                userContext.saveParentReports(parentReport.data);
              }
            );
            getSchoolSubscriptions(requestConfig, schoolId, data.data.userResponse.data.edu.user.teacherRrkChildId).then(
              (schoolSubscription) => {
                console.log('get sub 5')
                userContext.saveSubscriptions(schoolSubscription, data.data.userResponse.data.edu.user);
              }
            );
          }

          userContext.setFormProgress(80);
          if (data.data.userResponse.data.type === "edu-admin") {
            getTeachers(requestConfig, "edu").then((teachers: any) => {
              userContext.saveTeachers(
                teachers,
                data.data.userResponse.data.edu.user.relatedAccountId,
                "admin"
              );
            });
          } else {
            const teachers = { data: [] };
            userContext.saveTeachers(teachers, null, "teacher");
          }

          getSchoolReports(requestConfig, schoolId).then((schoolReport) => {
            userContext.saveSchoolReports(schoolReport);
          });

          dashboardContext.setInfo(token);
        }
        hideSpinner()
        setStatus(true);
        userContext.setFormProgress(100);
      });
    }
  };

  const redirectUser = () => {
    userContext.changeLoginFlag();
    let redirectPage = "/adm/dashboard";
    if (activateTeacher) {
      redirectPage = "/teachers/dashboard";
    }
    if(activateTeacherParent || activateParent) {
      redirectPage = "/parent/dashboard";
    }
    navigate(redirectPage, { state: { setPassword: true } });
  };

  useEffect(() => {
    document.title =
      "Set your Password and agree to our Terms of Service | Reading Is Fundamental";
    if (
      status &&
      dashboardContext.information &&
      userContext.userData &&
      userContext.subscriptions &&
      userContext.userData &&
      userContext.teachers &&
      userContext.schoolReports &&
      userContext.parentReports
    ) {
      redirectUser();
    }
  }, [
    dashboardContext.information,
    status,
    userContext.userData,
    userContext.subscriptions,
    userContext.userData,
    userContext.teachers,
    userContext.schoolReports,
  ]);

  return (
    <div className="sign-in-page set-new-password">
      <NavBar />
      <div className="pt-5">
        <ButtonMenu />
      </div>
      <div>
        <h1 className="sign-in-title">
          Set your Password and agree to our Terms of Service
        </h1>
        <div className="container p-4">
          <div className="row">
            <div className="">
              <div className="login-form px-2">

                <Formik
                  initialValues={{
                    email: "",
                    confirmEmail: "",
                    password: "",
                    confirmPassword: "",
                    agreement: false,
                  }}
                  validationSchema={activateTeacherParent ? validateParent : validate}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ errors }) => (
                    <Form>
                      {Object.keys(errors).length > 0 ? (
                        <div className="errors">
                          <div className="col-1 p-0 error-logo" />
                          <ul className="p-0 errors-list">
                          <ErrorMessage
                              name="email"
                              component={() => (
                                <div className="error-message">
                                  <TextError
                                    text={errors.email}
                                    wrapper={"li"}
                                  />
                                </div>
                              )}
                            />
                             <ErrorMessage
                              name="confirmEmail"
                              component={() => (
                                <div className="error-message">
                                  <TextError
                                    text={errors.confirmEmail}
                                    wrapper={"li"}
                                  />
                                </div>
                              )}
                            />
                            <ErrorMessage
                              name="password"
                              component={() => (
                                <div className="error-message">
                                  <TextError
                                    text={errors.password}
                                    wrapper={"li"}
                                  />
                                </div>
                              )}
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component={() => (
                                <div className="error-message">
                                  <TextError
                                    text={errors.confirmPassword}
                                    wrapper={"li"}
                                  />
                                </div>
                              )}
                            />
                            <ErrorMessage
                              name="agreement"
                              component={() => (
                                <TextError
                                  wrapper={"li"}
                                  text={errors.agreement}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      ) : null}
                      {
                        activateTeacherParent && (
                          <>
                            <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-5">
                              <label className="label form-label" htmlFor="email">
                                Set your account email
                                <span className="form-required"> *</span>
                              </label>
                              <Field
                                className={`form-control email-form ${
                                  errors.email && "is-invalid"
                                }`}
                                type="text"
                                id="email"
                                name="email"
                              />
                            </div>
                            <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-5">
                              <label className="label form-label" htmlFor="confirmEmail">
                                Repeat your email
                                <span className="form-required"> *</span>
                              </label>
                              <Field
                                className={`form-control email-form ${
                                  errors.confirmEmail && "is-invalid"
                                }`}
                                type="text"
                                id="confirmEmail"
                                name="confirmEmail"
                              />
                            </div>
                          </>
                        )
                      }
                      <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-5">
                        <label className="label form-label" htmlFor="password">
                          Password
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control email-form ${
                            errors.password && "is-invalid"
                          }`}
                          type="password"
                          id="password"
                          name="password"
                        />
                      </div>
                      <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mb-4">
                        <label
                          className="label form-label"
                          htmlFor="confirmPassword"
                        >
                          Repeat Password
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.password && "is-invalid"
                          }`}
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                        <div className="description text-start">
                          Password must be 8 or more characters and contain one
                          number, a capital, or a special character.
                        </div>
                      </div>
                      <div className="col-sm-10 col-lg-4 offset-sm-1 offset-lg-4 mt-4 d-flex justify-content-center">
                        <Field
                          className="form-check-input p-0 align-middle"
                          type="checkbox"
                          name="agreement"
                          id="agreement"
                        />
                        <label htmlFor="agreement" className="mb-4">
                          <span className="label form-label accept-label">
                            Reading Is Fundamental
                          </span>
                          <a
                            className="accept-terms ms-1"
                            href="https://skybrary.org/school/terms-of-use"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms of Use
                          </a>
                        </label>
                      </div>

                      <div className="d-grid col-6 mx-auto">
                        <button
                          className="button log-in-button btn-moderate-green btn-spinner-indicator"
                          type="submit"
                          disabled={buttonDisabled}
                        >
                          {`Continue >>`}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default FormSignIn;
