import { Link, useNavigate } from "react-router-dom";
import Table from "@components/Dashboard/Common/Table";
import React, { useMemo } from "react";
import { isCleverTheme } from "@utils/helpers";
import { useUserContext } from "@context/user";
import { useCleverContext } from "@context/clever";
import { getCleverSubscriptionByMultipleIds } from "@api/rifService";
import Cookies from 'universal-cookie'

const AdminStepTwo = (props: { text: string; to: string; linkText: string; linkClassName: string; toRenew: string}) => {

  const userContext = useUserContext()
  const cleverContext = useCleverContext()

  const isClever = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext]
  );

  const cookies = new Cookies()
  const navigate = useNavigate();

  const getCleverSubscription = async () => {
    const cleverSubscription = await getCleverSubscriptionByMultipleIds(props.toRenew, userContext.token, cookies.get('SKYBRARY_CLEVER_DISTRICT_TOKEN'));
    cleverContext.saveRenewSubscriptions(cleverSubscription.data)
  };

  const handleClick = async () => {
    if (props.toRenew.length > 0) getCleverSubscription()
    navigate('/adm/checkout', {state: {ids: props.toRenew}});
  };

  return (
    <div className="step-two">
      {isClever ? (
        <>
          {props.text}
          <button
            onClick={handleClick}
            className={`step-two-link ms-1 ${props.linkClassName || ''} text-uppercase`}
          >
            buy here
          </button>
        </>
      ) : (
        <>
          {props.text}
          <Link className={`step-two-link ms-1 ${props.linkClassName || ''}`} to={props.to}>{props.linkText}</Link>
        </>
      )}
    </div>

  );
};

export default AdminStepTwo;
