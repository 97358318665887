import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getCleverSubscriptionById,
  getSubscriptionReports,
  getSubscriptionReportsForClever,
  getTeacherOfDistrict,
} from "../../../../../api/rifService";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import { SKYBRARY_CLEVER_DISTRICT_TOKEN } from "../../../../../utils/constants";
import { isCleverTheme } from "../../../../../utils/helpers";
import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import ModalWindow from "../../../Common/ModalWindow";
import ReadingHistoryModal from "../../../Common/Modals/ReadingHistory";
import Table from "../../../Common/Table";
import { RequestConfigTypes } from "../../../../../types/requestTypes";
import { useUserContext } from "@context/user";
import { useCleverContext } from "@context/clever";

let controller = new AbortController();

const ReportDetail = () => {
  const cookies = new Cookies();
  const collapseTextField = ["admin", "report_classroom"];
  let mappedTableData: any[] = [];
  const subscriptionId = useParams().subscriptionId;
  const userContext = useUserContext();
  const cleverContext = useCleverContext();

  const requestConfig: RequestConfigTypes = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [isLoading, setIsLoading] = useState(true);
  const [className, setClassName] = useState("");
  const [grade, setGrade] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const cleverUser = cleverContext.isCleverUser();
  const [dataTable, setDataTable] = useState([]);

  const getTeacherName = (id: string | null, teachersList: any) => {
    const foundTeacher = teachersList.find(
      (teacher: any) => teacher.edu.eduUserId === id,
    );

    if (foundTeacher) {
      setTeacherName(
        `${foundTeacher.edu.firstName} ${foundTeacher.edu.lastName}`,
      );
    } else {
      setTeacherName("UNASSIGNED");
    }
  };

  useEffect(() => {
    if (cleverUser) {
      const fetchSubscription = async () => {
        try {
          const cleverSubscription = await getCleverSubscriptionById(
            subscriptionId,
            userContext.token,
            cookies.get("SKYBRARY_CLEVER_DISTRICT_TOKEN"),
          );
          const { cleverSectionId, eduSubscription } = cleverSubscription.data;

          if (cleverSubscription.data) {
            setClassName(eduSubscription["class_name"]);
            setGrade(eduSubscription["grade"]);

            if (cleverSectionId) {
              requestConfig.headers.cleveraccesstoken = cookies.get(
                SKYBRARY_CLEVER_DISTRICT_TOKEN,
              );

              getSubscriptionReportsForClever(
                requestConfig,
                cleverSectionId,
              ).then((subscriptionReport) => {
                userContext.setFormProgress(100);
                setIsLoading(false);

                //Get subscription info and set into data table object
                mapTableData(subscriptionReport);
                getTeacherOfDistrict(requestConfig).then(
                  (responseTeachers: any) => {
                    userContext.saveTeachers(
                      responseTeachers,
                      userContext.userData.edu.user.relatedAccountId,
                      "admin",
                    );
                    getTeacherName(
                      eduSubscription["rrk_teacher_id"],
                      responseTeachers.data.teachers,
                    );
                    setIsLoading(false);
                  },
                );
              });
            }
          }
        } catch (e) {
          console.error("Error fetching subscription:", e);
        }
      };

      fetchSubscription();
    } else {
      const filteredSub = userContext.subscriptions.find(
        (subscription: any) => {
          return subscription.rrkSubscriptionEduId === Number(subscriptionId);
        },
      );
      if (filteredSub) {
        setClassName(filteredSub.className);
      }
    }
  }, []);

  useEffect(() => {
    document.title = "Report Detail | Reading Is Fundamental";
    userContext.setFormProgress(50);

    //No clever user subscription reports
    if (!cleverUser) {
      getSubscriptionReports(requestConfig, subscriptionId).then(
        (subscriptionReport) => {
          userContext.setFormProgress(100);
          setIsLoading(false);
          mapTableData(subscriptionReport);
        },
      );
      return () => {
        handleCancel();
      };
    }
  }, []);

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = (
    history: object,
    childName: string,
    booksRead: number,
    type: string,
  ) => {
    setModal({
      show: true,
      class: "reading-history-modal",
      onClick: handleClose,
      header: (
        <h1 className="title">
          {type === "book" ? "Books List" : "Video List"}
        </h1>
      ),
      body: (
        <ReadingHistoryModal
          onClick={handleClose}
          onSubmit={handleClose}
          readingHistory={history}
          childName={childName}
          booksRead={booksRead}
          className={className}
          type={type}
        />
      ),
    });
  };

  const mapTableData = (subscriptionReport: any) => {
    let totalBooksRead: number = 0;
    let totalTimeReading: number = 0;
    let totalVideosWathched: number = 0;

    if (subscriptionReport.length > 0) {
      mappedTableData = subscriptionReport.map((report) => {
        totalBooksRead = totalBooksRead + report.booksRead;
        totalTimeReading =
          totalTimeReading + Math.ceil(report.totalReadingTime / 60);
        totalVideosWathched = totalVideosWathched + report.videosWatched;
        let colWrapperClass = "report-cell";
        let reportStar = <></>;

        const getLastFourHistory = (lastFour: any, type: string) => {
          const lastFourResources = lastFour.slice(0, 4);
          const resources: any = lastFourResources.map((history: any) => {
            return (
              <div className="mb-1">
                {type === "books" ? history.bookName : history.videoName}
              </div>
            );
          });
          return resources;
        };

        const getLastElementHistory = (lastElement: any[], type: string) => {
          if (!Array.isArray(lastElement)) return null;

          const resources = lastElement.length > 0 ? lastElement[0] : null;
          return (
            <div className="mb-1">
              {type === "books" ? resources.bookName : resources.videoName}
            </div>
          );
        };

        if (report.booksRead > 0) {
          colWrapperClass += " has-read";
          reportStar = <div className="report-star" />;
          if (isCleverTheme(userContext, cleverContext)) {
            reportStar = (
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0L15.3064 8.2918H24.3882L17.0409 13.4164L19.8473 21.7082L12.5 16.5836L5.15268 21.7082L7.95911 13.4164L0.611794 8.2918H9.69357L12.5 0Z"
                  fill="#800484"
                />
              </svg>
            );
          }
        }

        return {
          col1: (
            <div className={colWrapperClass}>
              {report.childName}
              {reportStar}
            </div>
          ),
          col2: <div className={colWrapperClass}>{report.booksRead}</div>,
          col3: (
            <div className={colWrapperClass}>
              {Math.ceil(report.totalReadingTime / 60)}
            </div>
          ),
          col4:
            report.booksRead > 0 &&
            (!cleverUser ? (
              <>
                <div className={`last-four has-read`}>
                  {getLastFourHistory(report.readingHistory, "books")}
                </div>
                <div className="reading-history-container">
                  <button
                    type="button"
                    onClick={() =>
                      handleModal(
                        report.readingHistory,
                        report.childName,
                        report.booksRead,
                        "book",
                      )
                    }
                    className="reading-history-button"
                  >
                    See All
                  </button>
                </div>
              </>
            ) : (
              <div className={`last-four has-read`}>
                {getLastElementHistory(report.readingHistory, "books")}
              </div>
            )),
          col5: <div className={colWrapperClass}>{report.videosWatched}</div>,
          col6:
            report.videosWatched > 0 &&
            (!cleverUser ? (
              <>
                <div
                  className={`last-four ${
                    report.booksRead > 0 ? "has-read" : ""
                  }`}
                >
                  {getLastFourHistory(report.videoViewingHistory, "videos")}
                </div>
                <div className="reading-history-container">
                  <button
                    type="button"
                    onClick={() =>
                      handleModal(
                        report.videoViewingHistory,
                        report.childName,
                        report.videosWatched,
                        "video",
                      )
                    }
                    className="reading-history-button"
                  >
                    See All
                  </button>
                </div>
              </>
            ) : (
              <div
                className={`last-four ${
                  report.booksRead > 0 ? "has-read" : ""
                }`}
              >
                {getLastElementHistory(report.videoViewingHistory, "videos")}
              </div>
            )),
          col7: (
            <div className={colWrapperClass}>
              <>
                {report.lastUsed
                  ? format(report.lastUsed, "MM/dd/yyyy")
                  : "Never"}
              </>
            </div>
          ),
        };
      });

      mappedTableData.push({
        col1: "Totals",
        col2: totalBooksRead,
        col3: totalTimeReading,
        col4: "",
        col5: totalVideosWathched,
        col6: "",
        col7: "",
      });

      setDataTable(mappedTableData);
    } else {
      setClassName("No Class");
    }
  };

  const items = [
    {
      step: 1,
      text: "Admin Dashboard",
      link: "/adm/dashboard",
    },
    {
      step: 2,
      text: "Reports",
      link: "/adm/reports",
    },
    {
      step: 3,
      text: "Report Details",
      link: `/adm/reports/classroom/${subscriptionId}`,
    },
  ];

  let columnsHeaders = [
    {
      Header: "Student",
      accessor: "col1",
      width: 212,
    },
    {
      Header: "Books",
      accessor: "col2",
      width: 72,
    },
    {
      Header: isCleverTheme(userContext, cleverContext)
        ? "Time Reading (minutes)"
        : "Reading Time (mins.)",
      accessor: "col3",
      width: 93,
    },
    {
      Header: isCleverTheme(userContext, cleverContext)
        ? "Last Book Read"
        : "Last 4 Books Read",
      accessor: "col4",
      width: 144,
    },
    {
      Header: "Videos",
      accessor: "col5",
      width: 56,
    },
    {
      Header: isCleverTheme(userContext, cleverContext)
        ? "Last Video Watched"
        : "Last 4 Videos Watched",
      accessor: "col6",
      width: isCleverTheme(userContext, cleverContext) ? 85 : 164,
    },
  ];

  if (!isCleverTheme(userContext, cleverContext)) {
    columnsHeaders.push({
      Header: "Last Used",
      accessor: "col7",
      width: 127,
    });
  }

  return (
    <Dashboard rol="admin">
      <div className="reports detailed district-detail-reports-wrapper">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <h1 className="text-center main-title">Reports Details</h1>
            <div className="row user-classroster admin-table">
              <div className="d-flex align-items-center">
                <div className="col-5 table-header text-uppercase">
                  TEACHER: {teacherName}
                </div>
                <div className="col-5 table-header">
                  <span className="text-uppercase">Grade level: </span>
                  {grade}
                </div>
                <div className="col-2 px-0 manage-teachers-button-container d-flex flex-row-reverse">
                  <Link
                    to="/adm/reports"
                    className="btn btn-table-moderate-green"
                  >
                    GO BACK
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Steeper steps={items} activeStep={3} steeperType="dashboard" />
            <CollapseText
              dashboardField={collapseTextField}
              bodyImg=""
              title="Report Detail"
            />
            <div className="main-header user-classroster admin-table">
              <div>{className}</div>
              <div className="col-8 px-0 manage-teachers-button-container d-flex flex-row-reverse">
                <Link
                  to="/adm/reports"
                  className="btn-red-damask admin-table-button done-button"
                >
                  Done
                </Link>
              </div>
            </div>
          </>
        )}

        <Table
          data={dataTable}
          columns={columnsHeaders}
          modalClass="invert-colors report-table"
          isLoading={isLoading}
        />

        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
};

export default ReportDetail;
