import React, { useContext } from "react";
import { FormPurchaseContext } from "../../context/Forms/purchaseContext";


const RemoveRenewalButton = (props: {
  subscriptionIds: string[],
  isClever: boolean,
  id: string,
}) => {
  const { setRenewals } = useContext(FormPurchaseContext)
  const removeRenewal = (id: string, subscriptionIds: string[]) => {
    setRenewals(subscriptionIds.filter((item: string) => item !== id));
  }

  return (
    <button
      className={`${props.isClever && 'btn-sky btn-solid-red'} remove-renewal`}
      type="button"
      onClick={() => removeRenewal(props.id, props.subscriptionIds)}>
      {props.isClever ? 'X' : 'REMOVE'}
    </button>
  )
}



export default RemoveRenewalButton
