import { Link } from "react-router-dom";
import successImg from "@images/success.png";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { FormPurchaseContext } from "@context/Forms/purchaseContext";
import CleverContext from "@context/clever";
import { registerText, SKYBRARY_CLEVER_REGISTER_TEXT } from "@utils/constants";
import OrderFormPdf from "@components/Forms/purchase/OrderFormPdf";

const AdminSuccessComponent = (props: {
  page: string;
  order: boolean;
  orderId: number | null;
}) => {
  const orderContext: any = useContext(FormPurchaseContext);
  const cleverContext = useContext(CleverContext);
  const location = useLocation();
  const [orderForm, setOrderForm] = useState(false);

  const isClever: boolean = cleverContext.isCleverUser();

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.orderForm) {
        setOrderForm(true);
      }
    }
  }, []);

  useEffect(() => {
    if (orderForm) {
      handlePrint();
    }
  }, [orderForm]);

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        await html2pdf().from(html).saveAs("Skybrary-order");
      }
    },
  });

  let purchaseOrder;
  if (!props.order) {
    purchaseOrder = {
      classRoom: orderContext.thirdStep.classroomPlan,
      school: orderContext.thirdStep.schoolPlan,
      renewals: orderContext.thirdStep.subscriptionsRenewals.length,
    };
  }

  let title = isClever
    ? SKYBRARY_CLEVER_REGISTER_TEXT[props.page].purchase_form.successTitle
    : registerText[props.page].successTitle;
  let successMsg = isClever
    ? SKYBRARY_CLEVER_REGISTER_TEXT[props.page].purchase_form.successMsg
    : registerText[props.page].successMessage;
  let successMsgBottom = registerText[props.page].successMsgBottom;

  if (props.order) {
    title = registerText[props.page].order_form.successTitle;
    successMsg = registerText[props.page].order_form.successMsg;
    successMsgBottom = registerText[props.page].order_form.successMsgBottom;

    if (isClever) {
      successMsg =
        SKYBRARY_CLEVER_REGISTER_TEXT[props.page].order_form.successMsg;
    }
  }

  useEffect(() => {
    orderContext.setRenewals([]);
  }, []);

  return (
    <div
      className={`successContainer ${props.page !== "admin_purchase" ? "" : "admin-success"}`}
    >
      <div className={`title ${isClever ? "mb-2" : "mb-4"}`}>{title}</div>
      <div>
        {isClever ? (
          <svg
            width="38"
            height="38"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 21.5208L17.875 26.7292L29.3333 15.2708M8.5 39.75H33.5C36.9518 39.75 39.75 36.9518 39.75 33.5V8.5C39.75 5.04822 36.9518 2.25 33.5 2.25H8.5C5.04822 2.25 2.25 5.04822 2.25 8.5V33.5C2.25 36.9518 5.04822 39.75 8.5 39.75Z"
              stroke="#3D4751"
              strokeWidth="4.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <img src={successImg} alt="success" />
        )}
      </div>
      <div className="message mt-3 mb-4">{successMsg}</div>
      {!props.order ? (
        <>
          {!isClever ? (
            <>
              <h1 className="order-number">Order #{props.orderId}</h1>
              <p className="order-info">
                {purchaseOrder?.classRoom} Classroom Plan(s)
              </p>
              <p className="order-info">
                {purchaseOrder?.school} School Plan(s)
              </p>
              <p className="order-info mb-4">
                {purchaseOrder?.renewals} Renewal(s)
              </p>
            </>
          ) : (
            <>
              <div className="success-download-form  mb-4">
                <a
                  href="https://schools.clever.com/"
                  target="_blank"
                  className="btn-sky btn-moderate-navy-blue go-to-clever"
                >
                  GO TO CLEVER
                </a>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="success-download-form mb-4">
          {isClever ? (
            <button
              className="btn-sky btn-moderate-navy-blue download-link"
              onClick={handlePrint}
            >
              Download order form
            </button>
          ) : (
            <>
              Download the order form{" "}
              <button className="download-link" onClick={handlePrint}>
                from here
              </button>
            </>
          )}
          <div className="d-none">
            <OrderFormPdf refToAsign={pdfRef} orderContext={orderContext} />
          </div>
        </div>
      )}
      <Link
        to="/adm/dashboard"
        className={
          isClever
            ? "btn-sky btn-moderate-green go-back"
            : "btn btn-back btn-brandy-punch"
        }
      >
        {isClever ? "RETURN TO DASHBOARD" : "GO BACK TO THE DASHBOARD"}
      </Link>
    </div>
  );
};

export default AdminSuccessComponent;
