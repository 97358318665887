import { Formik, Form, Field, FormikValues, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import {
  createTeacher,
  editTeacherInfo,
  migrateTrial,
} from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";

const ManageTeacherModal = (props: {
  onClick: () => void;
  onSubmit: any;
  modalType: string;
  teacherData: any;
  alsoAdmin: boolean
}) => {
  const validate = Yup.object({
    firstName: Yup.string().required("First name field is required.").max(128),
    lastName: Yup.string().required("Last name field is required.").max(128),
    emailAddress: Yup.string()
      .required("Username or email adress field.")
      .email("The email entered is not valid."),
  });

  const userContext = useContext(UserContext);
  const [teacherAddedError, setTeacherAddedError] = useState({
    status: false,
    message: <></>,
  });
  const [teacherUpdateError, setTeacherUpdateError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [trialAccount, setTrialAccount] = useState(false);
  const [teacherId, setTeacherId] = useState(0);
  const [errorTrial, setErrorTrial] = useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [edit, setEdit] = useState(false);

  const handleMigrateTeacher = async () => {
    userContext.setFormProgress(50);
    setButtonDisabled(true);
    const response = await migrateTrial(teacherId, requestConfig);
    if (response) {
      userContext.setFormProgress(100);
      props.onSubmit("add-teacher");
    } else {
      userContext.setFormProgress(100);
      setErrorTrial(true);
      setTrialAccount(false);
      setButtonDisabled(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    setButtonDisabled(true);
    setTeacherUpdateError(false);
    setTeacherAddedError({ status: false, message: <></> });
    setButtonDisabled(true);
    setTeacherId(0);
    setTrialAccount(false);
    setErrorTrial(false);
    userContext.setFormProgress(50);

    if (props.modalType === "add") {
      const response = await createTeacher(requestConfig, values);
      if (response.status) {
        props.onSubmit("add-teacher");
      } else {
        if (
          response.data.response.data.errorMessage === "1100"
        ) {
          setTeacherId(response.data.response.data.data.rrkUserId);
          setTrialAccount(true);
        } else {
          setTeacherAddedError({
            status: true,
            message: (
              <div
                dangerouslySetInnerHTML={{ __html: response.data.response.data.errorMessage }}
              />
            ),
          });
        }
        setButtonDisabled(false);
        userContext.setFormProgress(100);
      }
    } else if (props.modalType === "edit") {
      const response = await editTeacherInfo(
        requestConfig,
        values,
        props.teacherData.id
      );
      if (response) {
        props.onSubmit("edit-teacher");
        userContext.setFormProgress(100);
      } else {
        setTeacherUpdateError(true);
        setButtonDisabled(false);
        userContext.setFormProgress(100);
      }
    }
  };


  let teacherData = {
    firstName: "",
    lastName: "",
    email: "",
  };

  useEffect(() => {
    if (props.modalType === "edit") {
      setEdit(true);
    }
  }, []);

  if (props.teacherData !== "") {
    teacherData.firstName = props.teacherData.fN;
    teacherData.lastName = props.teacherData.lN;
    teacherData.email = props.teacherData.email;
  }

  return (
    <div className="modal-body-wrapper">
      {trialAccount && (
        <div className="trial-account-message px-5">
          The email address entered is currently assigned to another Skybrary
          School account. If you want to proceed and invite this teacher to your
          roster, please click "Confirm".
          <button
            className="btn-brandy-punch confirm-button mt-4 btn-spinner-indicator"
            type="button"
            onClick={handleMigrateTeacher}
            disabled={buttonDisabled}
          >
            CONFIRM
          </button>
        </div>
      )}
      {errorTrial && (
        <TextError
          wrapper="div"
          text="There was an error, We were not able to migrate the teacher, please try again"
        />
      )}
      {teacherUpdateError && (
        <div className="my-2">
          <TextError
            wrapper="div"
            text="There was an error, We were not able to update the teacher, please try again"
          />
        </div>
      )}
      {teacherAddedError.status && (
        <div className="my-2 repeated-email">
          <TextError
            wrapper="div"
            text={teacherAddedError.message}
          />
        </div>
      )}
      <div>
        <legend className="subtitle-wrapper">
          <span>Info</span>
          <div className="bar"></div>
        </legend>
      </div>
      <Formik
        initialValues={{
          firstName: teacherData.firstName,
          lastName: teacherData.lastName,
          emailAddress: teacherData.email,
        }}
        onSubmit={handleSubmit}
        validationSchema={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors }) => (
          <Form className="modal-form">
            {Object.keys(errors)?.length > 0 && (
              <div className="mt-2 mb-3 add-teachers-input-errors">
                <div className="add-teachers-errors-logo" />
                <ErrorMessage
                  name="firstName"
                  component={() => (
                    <div className="error-message">
                      <TextError text={`${errors.firstName}`} wrapper={"li"} />
                    </div>
                  )}
                />
                <ErrorMessage
                  name="lastName"
                  component={() => (
                    <div className="error-message">
                      <TextError text={errors.lastName} wrapper={"li"} />
                    </div>
                  )}
                />
                <ErrorMessage
                  name="emailAddress"
                  component={() => (
                    <div className="error-message">
                      <TextError text={errors.emailAddress} wrapper={"li"} />
                    </div>
                  )}
                />
              </div>
            )}
            <div className="form-item name">
              <label htmlFor="firstName">
                First Name<span className="form-required"> *</span>
              </label>
              <Field
                type="text"
                name="firstName"
                className="form-input name"
              ></Field>
            </div>

            <div className="form-item name">
              <label htmlFor="lastName">
                Last Name <span className="form-required"> *</span>
              </label>
              <Field
                type="text"
                name="lastName"
                className="form-input name"
              ></Field>
            </div>

            <div className="form-item">
              <label htmlFor="email">
                Email <span className="form-required"> *</span>
              </label>
              <Field
                type="text"
                name="emailAddress"
                className={`form-input email`}
                disabled={props.alsoAdmin}
              ></Field>
            </div>
            <div className="buttons-wrapper">
              <button
                type="button"
                className="btn-modal btn-mojo-modal cancel-button me-2 teacher-button"
                onClick={() => props.onClick()}
                disabled={buttonDisabled}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`btn-modal btn-brandy-punch ${!trialAccount ? "btn-spinner-indicator" : ""} teacher-button`}
                disabled={buttonDisabled}
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ManageTeacherModal;
