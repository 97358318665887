import { useContext } from "react";
import CleverContext from "../../../../context/clever";
import UserContext from "../../../../context/user";
import { getCleverSubscriptionByMultipleIds } from "@api/rifService";
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';

const CheckoutCounter = (props: { items: number, toRenew: string[] }) => {
  const cleverContext: any = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  const cookies = new Cookies()
  const navigate = useNavigate();

  const isClever: boolean = cleverContext.isCleverUser()

  const getCleverSubscription = async () => {
    const cleverSubscription = await getCleverSubscriptionByMultipleIds(props.toRenew, userContext.token, cookies.get('SKYBRARY_CLEVER_DISTRICT_TOKEN'));
    cleverContext.saveRenewSubscriptions(cleverSubscription.data)
  };

  const handleClick = async () => {
    getCleverSubscription()
    navigate('/adm/checkout', {state: {ids: props.toRenew}});
  };

  return (
    <div className="checkout-container">
      <div className="checkout-counter">
        {isClever ? (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.3846 8L18.6959 5.97674C18.8709 4.8395 19.8494 4 21 4M18.3846 8L17.3914 14.4562C17.1662 15.9197 15.907 17 14.4263 17H7.40653C6.00044 17 4.783 16.0234 4.47797 14.6508L3.8113 11.6508C3.39496 9.77725 4.82062 8 6.73987 8H18.3846ZM15.5 19.75C15.5 20.1642 15.1642 20.5 14.75 20.5C14.3358 20.5 14 20.1642 14 19.75C14 19.3358 14.3358 19 14.75 19C15.1642 19 15.5 19.3358 15.5 19.75ZM7.50002 19.75C7.50002 20.1642 7.16423 20.5 6.75002 20.5C6.3358 20.5 6.00002 20.1642 6.00002 19.75C6.00002 19.3358 6.3358 19 6.75002 19C7.16423 19 7.50002 19.3358 7.50002 19.75Z"
                stroke="#184378" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h5 className="d-inline-block mx-2">{props.items} Renewals</h5>
          </>
        ) : (
          <>
            Shopping Cart: <span className="items">{props.items} Items</span>
          </>
        )}
        <span>
          <a onClick={handleClick}
            className={`${isClever ? 'btn btn-table-moderate-green' : 'btn-brandy-punch checkout-button ms-2'}`}
          >
            CHECK OUT
          </a>
        </span>
      </div>
    </div>
  );
};

export default CheckoutCounter;
