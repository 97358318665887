const LoadingMessage = () => {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          className="clever-loading-logo"
          src="/images/pages/clever/clever-skybrary-sync.gif"
          alt="Logo loading clever"
        />
      </div>
    </div>
  );
};

export default LoadingMessage;
