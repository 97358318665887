import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useContext, useState } from "react";
import { designateAdmin } from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";
import TextWarning from "../../../TextWarning";
import { useNavigate } from "react-router-dom";

function DesignateAdmin(props: any) {
  const userContext = useContext(UserContext);
  let teachers: any = userContext.teachers;
  const schoolId = userContext.userData.edu.user.rrkSchoolId;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  teachers = teachers.data.teachers.filter(
    (teacher: object) =>
      !userContext.teachers.data.migration_requests.includes(teacher.userId)
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [disableButton, setDisableButton] = useState(false);
  const [activeTeachers, setActiveTeachers] = useState(0);

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    setDisableButton(true);
    setErrorMessage("");
    if (values.teacher === "") {
      props.changeModal("error-designate");
      props.onClick();
    } else {
      const response = await designateAdmin(
        requestConfig,
        { teacherId: values.teacher },
        schoolId
      );
      if (response.status) {
        userContext.setFormProgress(100);
        userContext.logout();
        navigate("/");
      } else {
        setErrorMessage(response.data.response.data.errorMessage);
        userContext.setFormProgress(100);
        setDisableButton(false);
      }
    }
  };

  const changeModal = () => {
    props.onClick();
    props.changeModal("add-teacher-modal");
  };

  const renderTeachers = () => {
    return teachers.map((teacher: any, index: number) => {
      if (teacher.edu.eduUserId === userContext.adminTeacher[0].edu.eduUserId) {
        return (
          <option value="" key={index}>
            Select
          </option>
        );
      } else {
        if (teacher.lastLogin !== null) {
          setActiveTeachers(1);
          return (
            <option value={teacher.edu.eduUserId} key={index}>
              {`${teacher.edu.firstName} ${teacher.edu.lastName}`}
            </option>
          );
        }
      }
    });
  };

  return (
    <div className="modal-body-wrapper">
      {errorMessage !== "" && (
        <div className="error-message">
          <TextError text={errorMessage} wrapper={"div"} />
        </div>
      )}
      <TextWarning>
        Please be careful. Once you designate a new admin you will no longer
        manage this account. If you are 100% sure, please proceed.
      </TextWarning>
      {activeTeachers === 0 && (
        <div className="error-message">
          <TextError
            text={"You don't have any active teachers."}
            wrapper={"div"}
          />
        </div>
      )}
      <div className="logged-out-warning mt-3">
        You'll be logged out after clicking SAVE.
      </div>
      <Formik
        initialValues={{
          teacher: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            <ErrorMessage
              name="teacher"
              component={() => (
                <div className="error-message">
                  <TextError text={errors.teacher} wrapper={"div"} />
                </div>
              )}
            />
            <div className="teachers-select">
              <label
                className="form-label col-form-label mb-2"
                htmlFor="teacher"
              >
                Please select an active teacher to designate him/her as the new
                admin.
              </label>
              <Field
                as="select"
                className="form-control form-select"
                name="teacher"
              >
                {renderTeachers()}
              </Field>
              <button
                type="button"
                className="btn-modal btn-brandy-punch btn-add-teacher ms-3"
                onClick={changeModal}
                disabled={disableButton}
              >
                +
              </button>
              <div className="buttons-wrapper mt-4">
                <button
                  type="button"
                  className="btn-modal btn-valencia"
                  onClick={() => props.onClick()}
                  disabled={disableButton}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-modal btn-di-serria btn-spinner-indicator"
                  disabled={disableButton}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DesignateAdmin;
