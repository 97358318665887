interface SelectSectionProps {
  studentSubscriptions: any;
  setSectionSelectForStudent: (internalSectionId: number) => void;
}

const SelectSection = ({
  studentSubscriptions,
  setSectionSelectForStudent,
}: SelectSectionProps) => {
  if (!Array.isArray(studentSubscriptions)) return null;

  if (studentSubscriptions.length === 1) {
    // Automatically redirect if there is exactly one subscription
    setSectionSelectForStudent(studentSubscriptions[0].internalSectionId);
    return null;
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          className="clever-logo-image"
          src="/images/pages/clever/logo.svg"
          alt="Logo clever"
        />
      </div>
      <h5 className="my-3">Please select the section</h5>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {studentSubscriptions.map((sub, index) => (
          <button
            className="btn-table-moderate-green help-select-section m-2"
            key={`${sub.intSectionId}-${index}`}
            onClick={() => setSectionSelectForStudent(sub.internalSectionId)}
          >
            {sub.className}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectSection;
