import { useContext, useState } from "react";
import {
  deleteAllStudents,
  deleteSingleStudent,
  deleteTeacher,
} from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";
import { getTeachers } from "../../../../api/rifService";

function DeleteTeacher(props: {
  id: any;
  onSubmit: any;
  onClick: any;
  from: string;
}) {
  const userContext = useContext(UserContext);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [successDeleted, setSuccessDeleted] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleDelete = async () => {
    setDeleteError(false);
    setButtonDisabled(true);
    userContext.setFormProgress(50);
    if (props.from === "class-roster-all") {
      if (!successDeleted) {
        const response = await deleteAllStudents(
          requestConfig,
          props.id.schoolId,
          props.id.eduSubId
        );
        if (response) {
          setSuccessDeleted(true);
          userContext.setFormProgress(100);
        } else {
          setDeleteError(true);
          setButtonDisabled(false);
          userContext.setFormProgress(100);
        }
      } else {
        props.onClick(false);
      }
    } else if (props.from === "manage-teachers") {
      if (!successDeleted) {
        const response = await deleteTeacher(requestConfig, props.id);

        if (response.status) {
          getTeachers(requestConfig, "edu").then((response: any) => {
            userContext.saveTeachers(
              response,
              userContext.userData.edu.user.relatedAccountId,
              "admin"
            );
            userContext.setFormProgress(100);
            setSuccessDeleted(true);
          });
        } else {
          if (response.data.response.status === 409) {
            props.onSubmit(
              "teacher-assigned",
              response.data.response.data.errorMessage
            );
            userContext.setFormProgress(100);
          } else {
            setDeleteError(true);
            setButtonDisabled(false);
            userContext.setFormProgress(100);
          }
        }
      } else {
        props.onSubmit("delete-teacher");
      }
    } else if (props.from === "class-roster-single") {
      if (!successDeleted) {
        const response = await deleteSingleStudent(
          requestConfig,
          props.id.schoolId,
          props.id.eduSubId,
          props.id.rosterId
        );
        if (response) {
          setSuccessDeleted(true);
          userContext.setFormProgress(100);
        } else {
          setDeleteError(true);
          setButtonDisabled(false);
          userContext.setFormProgress(100);
        }
      } else {
        props.onClick(false);
      }
    }
  };

  let buttons;

  if (props.from === "max-students-teachers") {
    buttons = (
      <div className="buttons-wrapper">
        <button
          type="button"
          className="btn-terracotta btn-max-students"
          onClick={() => props.onClick()}
        >
          OK!
        </button>
      </div>
    );
  } else {
    if (successDeleted) {
      buttons = (
        <div className="buttons-wrapper">
          <button
            type="button"
            className="btn-sail delete-teacher-modal-button btn-spinner-indicator"
            onClick={() => handleDelete()}
          >
            OK
          </button>
        </div>
      );
    } else {
      buttons = (
        <div className="buttons-wrapper">
          <button
            type="button"
            className="btn-silver2 delete-teacher-modal-button"
            onClick={() => props.onClick()}
            disabled={buttonDisabled}
          >
            No, Cancel!
          </button>
          <button
            type="button"
            className="btn-terracotta delete-teacher-modal-button btn-spinner-indicator"
            onClick={() => handleDelete()}
            disabled={buttonDisabled}
          >
            Yes, Delete it!
          </button>
        </div>
      );
    }
  }

  let title = "Are you Sure?";

  if (props.from === "class-roster-all") {
    title = "DANGER : You are going to delete all your students";
  } else if (props.from === "max-students-teachers") {
    title = "Notice";
  }

  return (
    <div className="modal-body-wrapper">
      <div className={"circle " + (successDeleted ? "hide" : "")}>
        <span className="sa-body"></span>
        <span className="sa-dot"></span>
      </div>
      <div className={"sa-success " + (!successDeleted ? "hide" : "animate")}>
        <span className="sa-line sa-tip animate-tip"></span>
        <span className="sa-line sa-long animate-long"></span>
        <div className="sa-placeholder"></div>
        <div className="sa-fix"></div>
      </div>
      {deleteError && (
        <TextError
          wrapper="div"
          text={`There was an error, We were not able to delete the ${
            props.from === "class-roster-all" ||
            props.from === "class-roster-single"
              ? "student(s)"
              : "teacher(s)"
          }, please try again`}
        />
      )}
      <h1 className="title">{!successDeleted ? title : "Deleted!"}</h1>
      <div className="text">
        <p className="delete-teacher-subtitle">
          {props.from === "max-students-teachers" ? (
            "Your classroom subscription includes 35 students. To include additional students please contact us at LiteracyNetwork@RIF.org"
          ) : (
            <>
              {!successDeleted
                ? "You will not be able to recover this item."
                : "The item was deleted."}
            </>
          )}
        </p>
      </div>
      <form>{buttons}</form>
    </div>
  );
}

export default DeleteTeacher;
