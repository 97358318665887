import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import FormCheckout from "../../../../Forms/purchase/FormCheckout";
import TextError from "../../../../TextError";
import CollapseText from "../../../Common/Collapse";
import UserContext from '../../../../../context/user'
import CleverContext from '../../../../../context/clever'
import { isCleverTheme } from "../../../../../utils/helpers";
import {DEFAULT_DISTRICT_ADMIN_SKY_THEME} from "../../../../../utils/constants";
import PopUpModal from "../../../Teacher/Modals/PopUpModal";

const AdminPurchase = () => {
  const cleverContext = useContext(CleverContext)
  const userContext: any = useContext(UserContext)
  const location: any = useLocation();

  let idsRenew: string[] = [];
  if (location.state !== null) {
    if (location.state?.ids) {
      idsRenew = location.state.ids;
    }
  }
  const [emptyError, setEmptyError] = useState(false);

  return (
    <Dashboard rol="admin">
      <div className="purchase-admin">
        {isCleverTheme(userContext,cleverContext) ? (
          <>
            <PopUpModal
              title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.title}
              body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.body}
              modalClass={'district-report-modal'}
            />
            <h1 className={'text-center main-title'}>Purchase Skybrary Subscriptions</h1>
          </>
        ) : (
          <CollapseText dashboardField={""} bodyImg="" title="Purchase"/>
        )}
        {emptyError && (
          <div className={`${!isCleverTheme(userContext, cleverContext) && 'mx-3'} empty-error`}>
            <TextError
              text="Please purchase the required number of Skybrary subscriptions."
              wrapper="div"
            />
          </div>
        )}
        <div className="admin-purchase-container">
          <FormCheckout
            formSteps={[]}
            form="admin_purchase"
            userInfo=""
            onError={setEmptyError}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export default AdminPurchase;
