import { useContext } from "react";
import CleverContext from '../../../../../../context/clever'
import UserContext from "../../../../../../context/user";
import { isCleverTheme } from "../../../../../../utils/helpers";

interface AdminInfoItemProps {
  children: React.ReactNode;
  classes: string;
  text: React.ReactNode;
  description?: string;
  subText?: string | React.ReactNode;
}

const AdminInfoItem = (props: AdminInfoItemProps) => {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
    return (
      <>
        {isCleverTheme(userContext, cleverContext) ? (
          <div className={`adm-info-item d-flex justify-content-between ${props.classes}`}>
            <div className={'ms-4 label'}>
              <div className={`info ${props.description}`}>{props.text}</div>
              <div className={`info subtitle ${props.description}`}>{props.subText}</div>
            </div>
            <div className="me-4 value">{props.children}</div>
          </div>
        ) : (
          <div className={`adm-info-item p-0 ${props.classes}`}>
            <span className={`info ${props.description}`}>{props.text}</span>
            <span className="value">{props.children}</span>
          </div>
        )}
      </>
    )
}

export default AdminInfoItem;
