import React from "react";

interface SubmitButtonProps {
  text?: string;
  isClever: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isClever,
  onClick,
  isDisabled,
  text = "submit",
}) => {
  let btnClass = isClever
    ? "button btn-sky btn-moderate-green btn-sky-lg"
    : "button btn-red-damask button-login btn-spinner-indicator"

  if (isDisabled) {
    btnClass += " btn-disabled"
  }

  return (
    <button
      className={btnClass}
      style={isDisabled ? {
        opacity: 0.5,
      } : {}}
      type="submit"
      onClick={onClick}
    >
      {isDisabled ? (
        <div className="spinner-border" role="status">
        </div>
      ) :
        <>
          {text}
        </>
      }
    </button>
  )
}



export default SubmitButton
