import { useContext } from 'react'
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import AdminSuccessComponent from "./AdminSuccessComponent";
import CollapseText from "../../../Common/Collapse";
import { useLocation } from "react-router-dom";
import Steeper from "../../../../steeper/Steeper";
import CleverContext from "../../../../../context/clever";
import UserContext from "../../../../../context/user";
import { isCleverTheme } from "../../../../../utils/helpers";
import PopUpModal from "../../../Teacher/Modals/PopUpModal";
import {DEFAULT_DISTRICT_ADMIN_SKY_THEME} from "../../../../../utils/constants";

const AdminSuccess = () => {
  const cleverContext = useContext(CleverContext)
  const userContext: any = useContext(UserContext)
  const location = useLocation();
  let order = false;
  let id: number | null = null;
  console.log('location state admin success', location.state)
  if (location.state !== null) {
    if (location.state.order) {
      order = true;
    }

    if (location.state.orderId !== null) {
      id = location.state.orderId;
    }
  }
  console.log('order id', id)
  return (
    <Dashboard rol="admin">
      <div className="purchase-admin success">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <PopUpModal
              title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchaseSuccess.title}
              body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchaseSuccess.body}
              modalClass={'district-report-modal'}
            />
            <h1 className={'text-center main-title'}>Purchase Skybrary Subscriptions</h1>
          </>
        ) : (
          <>
            <div className="steeper-purchase m-0">
              <Steeper
                steps={[
                  {
                    step: 1,
                    text: "Purchase",
                    link: "/adm/checkout",
                  },
                  {
                    step: 2,
                    text: "",
                    link: "",
                  },
                ]}
                activeStep={2}
                steeperType="dashboard"
              />
            </div>
            <CollapseText dashboardField={""} bodyImg="" title="Purchase"/>
          </>
        )}

        <div className="purchase-page success-page">
          <div className="container">
            <div className="row mx-auto main-content p-0">
              <div className="admin-purchase-container">
                <AdminSuccessComponent page="admin_purchase" order={order} orderId={id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AdminSuccess;
