import { useContext, useEffect, useState } from "react";
import { emailToParents, emailToTeachers } from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import sendImage from "../../../../images/icons/send_email_icon.png";
import successTick from "../../../../images/success.png";
import TextError from "../../../TextError";

let controller = new AbortController();

function ActivationEmail(props: {
  onClick: (success: boolean, form: string) => void;
  targets: string;
  id: number;
  parentsIds: string[];
  from: string;
  subscriptionId: any;
  toName: string;
}) {
  const userContext: any = useContext(UserContext);
  const schoolId = userContext.userData.edu.user.rrkSchoolId;

  let ids: any = [];
  let toAllParents = false;  
  let toText: string = "";

  if (props.targets === "all") {
    toAllParents = true;
    if (props.from === "teacher") {
      toText = "All My Teachers"
      //Filter the subscritions with teachers to send emails to
      const teachersToSendMailList = userContext.subscriptions.filter(
        (sub) => sub.teacherId !== userContext.userData.edu.user.rrkEduUserId && sub.teacherId !== null 
      );
      //Get the teacherId of the filtered subscription list
      teachersToSendMailList.forEach(
        (sub) => ids.push(sub.teacherId)
      );    
    } else {
      toText = "All Parents"
      ids = props.parentsIds;
    }
  } else {
    if (props.from === "teacher") {
      ids.push(props.id);
    } else {
      ids = props.parentsIds;
    }
    toText = props.toName;
  }

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = () => {
    setButtonDisabled(true);
    setErrorEmail(false);
    userContext.setFormProgress(50);
    if (props.from === "teacher") {
      emailToTeachers(requestConfig, schoolId, ids).then((response) => {
        if (response) {
          setEmailSent(true);
        } else {
          setErrorEmail(true);
          setButtonDisabled(false);
        }
        userContext.setFormProgress(100);
      });
    } else {
      emailToParents(requestConfig, props.id, ids, toAllParents, Number(props.subscriptionId)).then((response) => {
        if (response) {
          setEmailSent(true);
        } else {
          setErrorEmail(true);
          setButtonDisabled(false);
        }
        userContext.setFormProgress(100);
      });
    }
  };

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => props.onClick(false, ""), 1700);
    }

    return () => {
      handleCancel();
    };
  }, [emailSent]);

  return (
    <div className="modal-body-wrapper">
      {!emailSent ? (
        <h1 className="title send-email text-center">Activation Email</h1>
      ) : (
        <h1 className="title email-sent">Email Sent!</h1>
      )}
      <div className="image-container">
        {emailSent && (
          <img className="success-email mb-3" src={successTick} alt="success" />
        )}
        <img src={sendImage} alt="email" />
      </div>
      {errorEmail && (
        <div className="my-2">
          <TextError
            wrapper="div"
            text="There was an error, We were not able to send the emails, please try again"
          />
        </div>
      )}
      {!emailSent && (
        <div className="text">
          <p className="description">Send Activation Email</p>
          <p className="mail-to">{toText}</p>
        </div>
      )}
      {!emailSent && (
        <form>
          <div className="buttons-wrapper text-center">
            <button
              type="button"
              className="btn-modal btn-mojo-modal"
              onClick={() => props.onClick()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-modal btn-brandy-punch btn-spinner-indicator"
              onClick={handleSubmit}
              disabled={buttonDisabled}
            >
              Send
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ActivationEmail;
