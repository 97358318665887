import { useContext } from 'react'
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import CardInfo from "../../../../../pages/purchase/card";
import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import CleverContext from "../../../../../context/clever";
import UserContext from "../../../../../context/user";
import { isCleverTheme } from "../../../../../utils/helpers";
import PopUpModal from "../../../Teacher/Modals/PopUpModal";
import {DEFAULT_DISTRICT_ADMIN_SKY_THEME} from "../../../../../utils/constants";

const AdminPurchase = () => {
  const cleverContext = useContext(CleverContext)
  const userContext = useContext(UserContext)

  return (
    <Dashboard rol="admin">
      <div className="purchase-admin admin-card">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <PopUpModal
              title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.title}
              body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.body}
              modalClass={'district-report-modal'}
            />
            <h1 className={'text-center main-title'}>Purchase Skybrary Subscriptions</h1>
          </>
        ) : (
          <>
            <div className="steeper-purchase">
              <Steeper
                steps={[
                  {
                    step: 1,
                    text: "Purchase",
                    link: "/adm/checkout",
                  },
                  {
                    step: 2,
                    text: "",
                    link: "",
                  },
                ]}
                activeStep={2}
                steeperType="dashboard"
              />
            </div>
            <CollapseText dashboardField={""} bodyImg="" title="Purchase"/>
          </>
        )}
        <div className="admin-purchase-container">
          <CardInfo page="admin_purchase"/>
        </div>
      </div>
    </Dashboard>
  );
};

export default AdminPurchase;
