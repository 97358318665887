import { useEffect } from 'react'

const UnauthorizedCleverMessage = (title, body) => {
  useEffect(() => {
    document.title = 'Unauthorized | Reading Is Fundamental'
  }, [])
  return (
    <div className="unauthorized-page mt-5">
      <>
        <div className="page-content"/>
        <h3 className="page-text mb-5">
          {title}
        </h3>
        <div className="text-center">
          <p>{body}</p>
        </div>
      </>
    </div>
  )
}

export default UnauthorizedCleverMessage
