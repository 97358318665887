import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../../../../../../context/user";
import Dashboard from "../../../../../../pages/dashboard/Dashboard";
import TextSuccess from "../../../../../TextSuccess";
import LeftColumnInfo from "./LeftColumnInfo";
import RightColumnInfo from "./RightColumnInfo";
import CleverContext from '../../../../../../context/clever'
import TextError from "../../../../../TextError";
import { isCleverTheme } from "../../../../../../utils/helpers";
import { DEFAULT_DISTRICT_ADMIN_SKY_THEME } from "../../../../../../utils/constants";
import PopUpModal from "../../../../Teacher/Modals/PopUpModal";
import { UserData } from "../../../../../../types/userTypes"

const AdminDashboard = () => {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const location = useLocation();
  const [password, setPassword] = useState(false);
  const [dashboardMessage, setDashboardMessage] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    document.title = "My Home | Reading Is Fundamental";

    const summaryData = localStorage.getItem("summaryData")
    const userData: UserData | null = summaryData ? JSON.parse(summaryData) : null

    let invalidDistrict = false

    if (cleverContext.getCleverGiftDistrict()) {
      invalidDistrict = cleverContext.getCleverGiftDistrict() !== userData?.districtId
    }

    if (invalidDistrict) {
      setDashboardMessage({
        status: true,
        message: `You tried to redeem a clever gift that doesn't belong to your district.`,
      });
    }

    if (location.state !== null) {
      if (location.state?.setPassword) {
        userContext.saveIsLogging(false);
        setPassword(true);
        window.history.replaceState({}, "");
      }
    }
    return () => {
      userContext.saveHasPosterRendered(false);
    };
  }, []);

  const [students, setStudents] = useState(0);

  const countStudents = (studentsNumber: number) => {
    setStudents(studentsNumber);
  };

  return (
    <Dashboard rol="admin">
      <div className="district-main-wrapper">
        {dashboardMessage.status && (
          <div className="my-2 col-12">
            <TextError
              wrapper="div"
              text={dashboardMessage.message}
            />
          </div>
        )}
        {isCleverTheme(userContext, cleverContext) && (
          <PopUpModal
            title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.main.title}
            body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.main.body}
            modalClass={'district-main-modal'}
          />
        )}
        {password && (
          <div className="text-success-dashboards password-changed px-4">
            <TextSuccess text="Password Changed!"/>
          </div>
        )}
        <div className="row home-dashboard">
          <div className={`${isCleverTheme(userContext, cleverContext) ?  'col-5' : 'col-4'} p-0 left`}>
            <LeftColumnInfo students={students}/>
          </div>
          <div className="col-8 p-0 right">
            <RightColumnInfo countStudents={countStudents}/>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AdminDashboard;
