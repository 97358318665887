import Steeper from "../../../../steeper/Steeper";
import MyAccountTabs from "./MyAccountTabs";
import SchoolManagementForm from "./SchoolManagementForm";
import { FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import { editSchoolInfo, getSchoolinfo } from "../../../../../api/rifService";
import TextSuccess from "../../../../TextSuccess";

import UserProvider from "../../../../../context/user";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import UserContext from "../../../../../context/user";

const SchoolManagement = () => {
  const tokenContext = useContext(UserProvider);
  const [success, setSuccess] = useState(false);
  const [content, setContent] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState({
    city: "",
    countryId: 0,
    isTitleOne: 0,
    name: "",
    phone: "",
    schoolId: "",
    zip: "",
    stateId: "",
    street: "",
    typeId: 0,
  });

  const userContext = useContext(UserContext);
  const schoolId = userContext.userData.edu.user.rrkSchoolId;

  const items = [
    {
      step: 1,
      text: "Admin Dashboard",
      link: "/adm/dashboard",
    },
    {
      step: 2,
      text: "School Management",
      link: "/adm/manage/school",
    },
  ];

  const getInfo = async () => {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${tokenContext.token}`,
      },
    };
    tokenContext.setFormProgress(50);
    getSchoolinfo(requestConfig, schoolId).then((response) => {
      if (response.data.stateId === null) {
        setInitialValues({
          city: response.data.city,
          countryId: response.data.countryId,
          isTitleOne: response.data.isTitleOne,
          name: response.data.name,
          phone: response.data.phone,
          schoolId: response.data.schoolId,
          stateId: 0,
          zip: response.data.zipcode,
          street: response.data.street,
          typeId: response.data.typeId,
        });
      } else {
        setInitialValues({
          city: response.data.city,
          countryId: response.data.countryId,
          isTitleOne: response.data.isTitleOne,
          name: response.data.name,
          phone: response.data.phone,
          schoolId: response.data.schoolId,
          stateId: response.data.stateId,
          zip: response.data.zipcode,
          street: response.data.street,
          typeId: response.data.typeId,
        });
      }
    });
    tokenContext.setFormProgress(100);
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (initialValues.city !== "") {
      setContent(true);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues) => {
    setButtonDisabled(true);
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${tokenContext.token}`,
      },
    };
    tokenContext.setFormProgress(50);
    let data = values;

    if (parseInt(values.country) !== initialValues.countryId) {
      data = {
        name: values.school,
        street: values.street,
        countryId: values.country,
        stateId: 0,
        city: values.city,
        zipcode: values.zip,
        phone: values.schoolPhone,
        typeId: values.schoolType,
        isTitleOne: values.isTitleOne,
      };
    } else if (parseInt(values.country) === 0) {
      data = {
        name: values.school,
        street: values.street,
        countryId: values.country,
        stateId: values.stateId,
        city: values.city,
        zipcode: values.zip,
        phone: values.schoolPhone,
        typeId: values.schoolType,
        isTitleOne: values.isTitleOne,
      };
    } else {
      data = {
        name: values.school,
        street: values.street,
        countryId: values.country,
        city: values.city,
        zipcode: values.zip,
        phone: values.schoolPhone,
        typeId: values.schoolType,
        isTitleOne: values.isTitleOne,
      };
    }
    editSchoolInfo(requestConfig, data, schoolId).then((response) => {
      if (response) {
        setSuccess(true);
      } else {
        console.log(response);
      }
      setButtonDisabled(false);
      getInfo();
    });
  };

  return (
    <Dashboard rol="admin">
      <div className="px-3 adm-manage-school">
        <Steeper steps={items} activeStep={2} steeperType="dashboard" />
        <div className="row page-title">
          <h1>
            <span className="title">My Account</span>
            <div className="bar my-account-bar"></div>
          </h1>
        </div>
        <div className="account-tabs">
          <MyAccountTabs />
        </div>
        <div className="school-form py-3">
          {success && <TextSuccess text={"School data updated."} />}
          {content && (
            <SchoolManagementForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              buttonState={buttonDisabled}
            />
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default SchoolManagement;
