import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CleverContext, { CleverProvider } from '../../context/clever'
import { validateGiftToken } from '../../api/rifService'
import { redeemEduGiftCodeEvent } from '../../utils/events/googleAnalitycs'

const CleverSignIn = ({waitForParent = false, isValidForm = false}) => {
  let cleverContext = useContext(CleverContext)
  const location = useLocation()
  const [giftRedeemPage, setGiftRedeemPage] = useState(false);
  const [purchasePage, setPurchasePage] = useState(false);
  let giftCode = cleverContext.getCleverGiftToken();

  useEffect(() => {
    if (waitForParent) {
      handleSignInClick(giftCode)
    }
  }, [isValidForm])

  const handleSignInClickForGiftPage = async (giftCode: any) => {
    setGiftRedeemPage(detectGiftRedeemPage());
    giftCode = giftCode.trim()
    const response = await validateGiftToken(giftCode)
    if (response.status) {
      cleverContext.saveCleverGiftDistrict(response.gift.districtId)
      redeemEduGiftCodeEvent(location.pathname)
    }
  }

  /**
   * @todoClever: Here we should create the clever user https://rrkidz.atlassian.net/browse/CLEVER-47
   */
  const handleSignInClickForPurchasePage = async () => {
    setPurchasePage(detectPurchasePage())
  }

  const detectGiftRedeemPage = () => {
    const path = location.pathname
    const isGiftPage = (path === '/gift/redeem/manual')
    console.log('clever context', cleverContext);
    return isGiftPage
  }

  const detectPurchasePage = () => {
    const path = location.pathname
    const isPurchasePage = (path === '/purchase')
    console.log('clever context', cleverContext);
    return isPurchasePage
  }

  const handleSignInClick = (giftCode: any) => {
    const giftRedeemPage = detectGiftRedeemPage()
    const purchasePage = detectPurchasePage()
    if (isValidForm) {
      const uri = encodeURIComponent(`${process.env.REACT_APP_EDU_DOMAIN}/clever`)
      const clientId = process.env.REACT_APP_CLEVER_CLIENT_ID
      cleverContext.saveComeFromGiftRedeemPage(giftRedeemPage)
      cleverContext.saveComePurchasePage(purchasePage)
      if (giftRedeemPage) {
        handleSignInClickForGiftPage(giftCode).then(() => {
          console.log('gift set')
        })
      } else if (purchasePage) {
        handleSignInClickForPurchasePage().then(() => {
          console.log('purchase set')
        })
      }
      window.location.replace(`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${uri}&client_id=${clientId}`)
    }
  }


  return (
    <>
      {waitForParent ? (
        <button
          className={`button clever-log-in ${giftRedeemPage ? 'for-gift' : purchasePage ? 'for-purchase' : 'for-login'}`}/>
      ) : (
        <button
          className={`button clever-log-in ${giftRedeemPage ? 'for-gift' : purchasePage ? 'for-purchase' : 'for-login'}`}
          onClick={() => handleSignInClick(giftCode)}/>
      )}
    </>
  )
}

export default CleverSignIn
