import { sendEvent } from "../api/googleAnalyticsService";
import ButtonMenuMobile from "./ButtonMenuMobile";

const ButtonMenu = () => {
  const CONSUMER_DOMAIN = process.env.REACT_APP_CONSUMER_DOMAIN;

  return (
    <>
      <div className="button-menu margin-btn mx-auto">
        <div className="d-flex justify-content-center">
          <div className="item first">
            <a
              className="home-button btn btn-moderate-green"
              href={CONSUMER_DOMAIN + "/school"}
            >
              Home
            </a>
          </div>
          <div className="item">
            <a
              className="home-button btn btn-moderate-green"
              href={CONSUMER_DOMAIN + "/school/features"}
            >
              Features
            </a>
          </div>
          <div className="item">
            <a
              className="home-button btn btn-moderate-green"
              href={CONSUMER_DOMAIN + "/school/how-it-works"}
            >
              How it Works
            </a>
          </div>
          <div className="item">
            <a
              className="home-button btn btn-moderate-green"
              href={"/try-it-free"}
            >
              Try it free
            </a>
          </div>
          <div className="item">
            <a
              className="home-button btn btn-moderate-green"
              href={CONSUMER_DOMAIN + "/school/purchase"}
              onClick={()=> {
                sendEvent({
                  'event': 'click_purchase_now',
                  'eventProps': {
                    'category': 'interaction',
                    'action': 'user clicks {{Click Text}}',
                    'label': '{{Page URL}}',
                    'value': '0'
                    }
                });
              }}
            >
              Purchase
            </a>
          </div>
          <div className="item">
            <a
              className="home-button btn btn-moderate-green"
              href={"/gift/redeem/manual"}
            >
              Redeem
            </a>
          </div>
          <div className="item last">
            <a
              className="home-button btn btn-moderate-green"
              href={CONSUMER_DOMAIN + "/school/questions"}
            >
              Questions
            </a>
          </div>
        </div>
      </div>
      <ButtonMenuMobile consumerDomain={CONSUMER_DOMAIN as string} />
    </>
  );
};

export default ButtonMenu;
