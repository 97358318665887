import TableNoResult from "@components/Dashboard/Common/TableNoResult";
import {
  differenceInMonths,
  getDate,
  getMonth,
  getYear,
  isValid,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  getCleverSubscriptions,
  getSchoolReports,
  getSchoolSubscriptions,
  getTeachers,
} from "../../../../../api/rifService";
import { FormPurchaseContext } from "../../../../../context/Forms/purchaseContext";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import { DEFAULT_DISTRICT_ADMIN_SKY_THEME } from "../../../../../utils/constants";
import { isCleverTheme } from "../../../../../utils/helpers";
import Steeper from "../../../../steeper/Steeper";
import TextSuccess from "../../../../TextSuccess";
import CollapseText from "../../../Common/Collapse";
import ModalWindow from "../../../Common/ModalWindow";
import PaginatedTable from "../../../Common/PaginatedTable";
import Table from "../../../Common/Table";
import PopUpModal from "../../../Teacher/Modals/PopUpModal";
import ActivationEmail from "../../AdminModals/ActivationEmail";
import ManageTeacherModal from "../../AdminModals/Add-EditTeacher";
import EditSubscriptionModal from "../../AdminModals/EditSubscription";
import CheckoutCounter from "../CheckoutCounter";
import AdminStepTwo from "../StepTwoAdmin";
import { useCleverContext } from "@context/clever";
import { useUserContext } from "@context/user";
import { Teacher } from "@customTypes/teacherTypes";
import { Sort } from "@customTypes/enums/sortEnum";

let controller = new AbortController();

const ManageSubscription = () => {
  let mappedTableData: any[] = [];
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const isClever = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );
  const purchaseContext: any = useContext(FormPurchaseContext);
  const collapseTextField = ["admin", isClever ? "school_clever" : "school"];
  const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);
  const [showExpiredSubscription, setShowExpiredSubscription] = useState(false);
  const [successText, setSuccessText] = useState("Classroom changes Saved");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [sortSelected, setSortSelected] = useState("");
  const schoolId: number = userContext.userData.edu.user.rrkSchoolId;
  const [allSubscriptions, setAllSubscriptions] = useState(
    userContext.userWithSubscription ? userContext.subscriptions : [],
  );

  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userContext.hasActiveSubscription) {
      setAllSubscriptions(userContext.subscriptions);
    }
  }, [userContext.hasActiveSubscription, userContext.subscriptions]);

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  let teacherName;
  const getTeacherName = (id: string) => {
    teacherName = teachers.find((teacher) => teacher.edu.eduUserId === id);
    if (teacherName) {
      teacherName = `${teacherName.edu.firstName} ${teacherName.edu.lastName}`;
      return teacherName;
    } else {
      return "UNASSIGNED";
    }
  };

  let teacherActivated: any;

  const getCleverTeacherActivated = (subscription: any) => {
    let requestedTeacher = subscription.edu;
    if (requestedTeacher) {
      if (subscription.eduSubscription.expired) {
        return <div className="activate-account pt-1">Expired</div>;
      } else if (requestedTeacher.lastLogin === null) {
        return <div className="activate-account pt-1">Never used</div>;
      } else {
        return <div className="activate-account pt-1">Account Activated</div>;
      }
    }
    return;
  };

  const getTeacherActivated = (id: any) => {
    teacherActivated = null;
    if (id !== null) {
      teachers.map((teacher) => {
        if (teacher.edu.eduUserId === id) {
          teacherActivated = teacher;
        }
      });

      if (teacherActivated) {
        if (teacherActivated.lastLogin === null) {
          let teacherID = teacherActivated.edu.eduUserId;
          let teacherFullName = `${teacherActivated.edu.firstName} ${teacherActivated.edu.lastName}`;
          return (
            <div className="activate-account pt-1">
              Email Sent{" "}
              <button
                className="resend-email-button"
                type="button"
                onClick={() => {
                  handleModal(
                    "activation-email-modal",
                    "",
                    "",
                    teacherID,
                    0,
                    "single",
                    teacherFullName,
                  );
                }}
              >
                Resend Email
              </button>
            </div>
          );
        } else {
          return <div className="activate-account pt-1">Account Activated</div>;
        }
      }
      return;
    } else {
      return;
    }
  };

  const [renewalsArray, setRenewalsArray] = useState<string[]>(
    purchaseContext.renewals ? purchaseContext.renewals : [],
  );

  const handleRenew = (event: any) => {
    let id: string = event.target.id;
    let button = document.getElementById(event.target.id);

    if (!button) {
      return;
    }

    if (button?.innerHTML === "Renew") {
      button.innerHTML = "Cancel Renew";
      setRenewalsArray((renewalsArray: string[]) => [...renewalsArray, id]);
    } else {
      button.innerHTML = "Renew";
      setRenewalsArray((renewalsArray: string[]) =>
        renewalsArray.filter((item) => item !== id),
      );
    }
  };

  useEffect(() => {
    document.title = "Manage My Subscriptions | Reading Is Fundamental";
    purchaseContext.setRenewals(renewalsArray);
  }, [renewalsArray]);

  let isOrderPending = userContext.userData.edu.school.orders.pending;
  const [isOrder, setIsOrder] = useState(isOrderPending);
  const [sendMail, setSendMail] = useState(false);

  const mapTableData = () => {
    const hasTeacherToSendMail = () => {
      let needSendMail = allSubscriptions.some((subscription: any) => {
        return (
          subscription.teacherId !==
            userContext.userData.edu.user.rrkEduUserId &&
          subscription.teacherId !== null
        );
      });
      setSendMail(needSendMail);
    };

    const getSubscriptionDate = (subscription) => {
      if (!isValid(subscription.expirationDate)) {
        return [false, { year: "", month: "", day: "" }];
      }
      let date = utcToZonedTime(new Date(subscription.expirationDate), "UTC");
      let dateParts = {
        year: getYear(date),
        month: getMonth(date) + 1,
        day: getDate(date),
      };

      let currentDate = new Date();
      let closerToExpire = differenceInMonths(date, currentDate) < 3;

      return [closerToExpire, dateParts];
    };

    if (isClever) {
      mappedTableData = allSubscriptions.map(
        (subscription: any, index: number) => {
          let selectedRenew = false;
          const [closerToExpire, dateParts] = getSubscriptionDate(subscription);
          if (
            purchaseContext?.renewals?.includes(
              subscription.eduSubscription?.rrkSubscriptionEduId.toString(),
            )
          ) {
            selectedRenew = true;
          }

          return {
            col1: index + 1 + (currentPage - 1) * 10,
            col2: subscription.school.name ? subscription.school.name : "-",
            col3: subscription.eduSubscription?.grade
              ? subscription.eduSubscription?.grade
              : "-",
            col4: subscription.edu?.userName
              ? subscription.edu?.userName
              : "Unassigned",
            col5: (
              <div>
                {Boolean(dateParts.year) ? (
                  <>
                    {`${dateParts.month < 10 ? "0" : ""}${dateParts.month}/${
                      dateParts.day < 10 ? "0" : ""
                    }${dateParts.day}/${dateParts.year}`}
                  </>
                ) : (
                  <>-</>
                )}
              </div>
            ),
            col6: (
              <div>
                {subscription.eduSubscription?.teacherId
                  ? getCleverTeacherActivated(subscription)
                  : "Unassigned"}
                {closerToExpire && Boolean(subscription.eduSubscription) && (
                  <button
                    id={subscription.eduSubscription.rrkSubscriptionEduId}
                    className="renew-cancel"
                    type="button"
                    onClick={handleRenew}
                  >
                    {selectedRenew ? "Cancel Renew" : "Renew"}
                  </button>
                )}
              </div>
            ),
          };
        },
      );

      setDataTable(mappedTableData);
    } else if (teachers?.length > 0) {
      if (!userContext.showErrorMsg) {
        hasTeacherToSendMail();
      }
      mappedTableData = allSubscriptions.map(
        (subscription: any, index: number) => {
          const [closerToExpire, dateParts] = getSubscriptionDate(subscription);

          let selectedRenew = false;
          if (
            purchaseContext.renewals.includes(
              subscription.rrkSubscriptionEduId.toString(),
            )
          ) {
            selectedRenew = true;
          }

          return {
            col1: index + 1,
            col2: (
              <div className={`${closerToExpire ? "close-to" : ""}`}>
                {`${dateParts.month < 10 ? "0" : ""}${dateParts.month}/${
                  dateParts.day < 10 ? "0" : ""
                }${dateParts.day}/${dateParts.year}`}
                {closerToExpire && (
                  <button
                    id={subscription.rrkSubscriptionEduId}
                    className="renew-cancel"
                    type="button"
                    onClick={handleRenew}
                  >
                    {selectedRenew ? "Cancel Renew" : "Renew"}
                  </button>
                )}
              </div>
            ),
            col3: subscription.className,
            col4: getTeacherName(subscription.teacherId),
            col5: getTeacherActivated(subscription.teacherId),
            col6: (
              <Link
                to=""
                className="btn btn-table-di-serria subscriptions-edit-teacher"
                onClick={() =>
                  handleModal(
                    "edit-subscription-modal",
                    subscription.rrkSubscriptionEduId,
                    subscription.className,
                    subscription.teacherId,
                    schoolId,
                    "",
                    "",
                  )
                }
              >
                Edit
              </Link>
            ),
          };
        },
      );
      if (mappedTableData.length === 0 && isOrderPending) {
        setIsOrder(true);
      }
      setDataTable(mappedTableData);
    }
  };

  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!isClever) {
      let linkClass = sendMail ? "d-block send-email-link" : "d-none";
      if (!userContext.isLoading && !userContext.showErrorMsg) {
        linkRef.current.className = linkClass;
      }
    }

    mapTableData();
  }, [
    teachers,
    allSubscriptions,
    userContext.subscriptions,
    userContext.teachers,
    sendMail,
    userContext.isLoading,
  ]);

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  useEffect(() => {
    if (cleverContext.isCleverUser()) {
      if (cleverContext.isDistrictAdmin() || cleverContext.isDistrictStaff()) {
        getCleverSubscriptions(
          requestConfig,
          showExpiredSubscription,
          currentPage,
        ).then((subscriptions) => {
          if (subscriptions.status) {
            const data = {
              cleverSubscriptions: subscriptions.data,
            };
            cleverContext.saveCleverSubscriptions(data.cleverSubscriptions);
            setAllSubscriptions(data.cleverSubscriptions);
            setLastPage(subscriptions.pagination.lastPage);
          }
        });
      }
    } else {
      setTeachers(userContext.teachers?.data.teachers);
    }
  }, [userContext.teachers, showExpiredSubscription]);

  const [dataTable, setDataTable] = useState(mappedTableData);

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleSubscription = (from: string) => {
    handleClose();
    setSubscriptionUpdated(true);
    userContext.setFormProgress(25);

    if (from === "add-teacher") {
      setSuccessText("Teacher Added");
      getTeachers(requestConfig, "edu").then((response: any) => {
        userContext.setFormProgress(100);
        userContext.saveTeachers(
          response,
          userContext.userData.edu.user.relatedAccountId,
          "admin",
        );
      });
    }

    userContext.setFormProgress(50);

    getSchoolSubscriptions(requestConfig, schoolId, null).then(
      (schoolSubscription) => {
        userContext.saveSubscriptions(
          schoolSubscription,
          userContext.userData.edu.user,
        );
        userContext.setFormProgress(100);
        setAllSubscriptions(schoolSubscription);
      },
    );
    mapTableData();

    getSchoolReports(requestConfig, schoolId).then((schoolReport) => {
      userContext.saveSchoolReports(schoolReport);
    });
  };

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  const handleModal = (
    modal: string,
    subscriptionId: string,
    className: string,
    teacherId: number = 0,
    schoolId: number,
    targets: string,
    fullName: string,
  ) => {
    switch (modal) {
      case "add-teacher-modal":
        setModal({
          show: true,
          class: "add-teacher-modal",
          onClick: handleClose,
          header: <h1>Add Teacher</h1>,
          body: (
            <ManageTeacherModal
              onClick={handleClose}
              onSubmit={handleSubscription}
              modalType="add"
              teacherData=""
              alsoAdmin={false}
            />
          ),
        });
        break;
      case "edit-subscription-modal":
        setModal({
          show: true,
          class: "edit-subscription-modal",
          onClick: handleClose,
          header: <h1>Edit Subscription</h1>,
          body: (
            <EditSubscriptionModal
              changeModal={handleModal}
              onClick={handleClose}
              onSubmit={handleSubscription}
              subscriptionId={subscriptionId}
              schoolId={schoolId}
              className={className}
              teacherId={teacherId}
            />
          ),
        });
        break;
      case "activation-email-modal":
        setModal({
          show: true,
          class: "activation-email-modal",
          onClick: handleClose,
          header: <h1></h1>,
          body: (
            <ActivationEmail
              onClick={handleClose}
              targets={targets}
              id={teacherId}
              parentsIds={[]}
              from="teacher"
              subscriptionId=""
              toName={fullName}
            />
          ),
        });
        break;
    }
  };

  const items = [
    {
      step: 1,
      text: "Admin Dashboard",
      link: "/adm/dashboard",
    },
    {
      step: 2,
      text: "Manage My Subscriptions",
      link: "/adm/school",
    },
  ];

  const eduColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "col1",
        width: 60,
      },
      {
        Header: "Expiration",
        accessor: "col2",
        width: 120,
      },
      {
        Header: "Class Name",
        accessor: "col3",
        width: 356.44,
      },
      {
        Header: "Teachers",
        accessor: "col4",
        width: 208.3,
      },
      {
        Header: (
          <div>
            <p className="send-mail">Status</p>
            <Link
              ref={linkRef}
              to=""
              className="send-email-link"
              onClick={() =>
                handleModal("activation-email-modal", "", "", 0, 0, "all", "")
              }
            >
              Send all
            </Link>
          </div>
        ),
        accessor: "col5",
        width: 100,
      },
      {
        Header: "",
        accessor: "col6",
        width: 100,
      },
    ],
    [],
  );
  const cleverColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "col1",
        width: isClever ? 65 : 88,
      },
      {
        Header: "School",
        slug: "school_name",
        accessor: "col2",
        width: 72.27,
      },
      {
        Header: "Grade",
        slug: "grade",
        accessor: "col3",
        width: 158.44,
      },
      {
        Header: "Teachers",
        slug: "teacher_username",
        accessor: "col4",
        width: 208.3,
      },
      {
        Header: "Expiration",
        slug: "expiration",
        accessor: "col5",
        width: 72.27,
      },
      {
        Header: "Status",
        slug: "status",
        accessor: "col6",
        width: isClever ? 112 : 87,
      },
    ],
    [],
  );

  const columns = isClever ? cleverColumns : eduColumns;
  const data = React.useMemo(() => dataTable, [dataTable]);

  const handlePage = (currentPage: number) => {
    setCurrentPage(currentPage);
    userContext.setFormProgress(50);

    getCleverSubscriptions(
      requestConfig,
      showExpiredSubscription,
      currentPage,
      sortSelected,
    ).then((subscriptions) => {
      if (subscriptions.status) {
        const data = {
          cleverSubscriptions: subscriptions.data,
        };
        cleverContext.saveCleverSubscriptions(data.cleverSubscriptions);
        setAllSubscriptions(data.cleverSubscriptions);
        setLastPage(subscriptions.pagination.lastPage);
        setIsTableLoading(false);
      }
    });
  };

  const CleverTable = () => {
    return data && data.length > 0 ? (
      <PaginatedTable
        data={data}
        columns={columns}
        modalClass="invert-colors"
        sortArrows={true}
        page={currentPage}
        handleChangePage={handlePage}
        handleSortSelected={handleSort}
        lastPage={lastPage}
        isTableLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
      />
    ) : (
      <TableNoResult
        data={data}
        columns={columns}
        modalClass="table-body-no-result"
      />
    );
  };

  const handleSort = (sortId: string, sortType?: Sort) => {
    if (sortType) {
      sortId += "." + sortType;
    }
    getCleverSubscriptions(
      requestConfig,
      showExpiredSubscription,
      currentPage,
      sortId,
    ).then((subscriptions) => {
      if (subscriptions.status) {
        const data = {
          cleverSubscriptions: subscriptions.data,
        };
        cleverContext.saveCleverSubscriptions(data.cleverSubscriptions);
        setAllSubscriptions(data.cleverSubscriptions);
        setSortSelected(sortId);
        setLastPage(subscriptions.pagination.lastPage);
        setIsTableLoading(false);
      }
    });
  };

  return (
    <Dashboard rol="admin">
      <div className="manage-subscriptions district-subscriptions-wrapper">
        {renewalsArray.length > 0 && (
          <CheckoutCounter
            items={renewalsArray.length}
            toRenew={renewalsArray}
          />
        )}
        {!isClever ? (
          <>
            <Steeper steps={items} activeStep={2} steeperType="dashboard" />
            <CollapseText
              dashboardField={collapseTextField}
              bodyImg=""
              title="Manage My Subscriptions"
            />
          </>
        ) : (
          <>
            <PopUpModal
              title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.subscriptions.title}
              body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.subscriptions.body}
              modalClass="district-subscriptions-modal"
            />
            <h1 className="text-center main-title">View My Subscriptions</h1>
          </>
        )}

        {subscriptionUpdated && (
          <div className="text-success-dashboards">
            <TextSuccess text={successText} />
          </div>
        )}
        <div
          className={`user-classroster admin-table ${!isClever ? "main-header" : ""}`}
          style={{ opacity: isTableLoading ? "0.6" : "1" }}
        >
          {!isClever ? (
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>All my Subscriptions</span>
              <span className="manage-teachers-button-container">
                <button
                  type="button"
                  className="btn-red-damask admin-table-button"
                  onClick={() =>
                    handleModal("add-teacher-modal", "", "", 0, 0, "", "")
                  }
                >
                  ADD TEACHER
                </button>
              </span>
            </div>
          ) : (
            <div className="d-flex justify-content-end py-2 ms-auto">
              <div className="form-check form-check-inline">
                <input
                  className={`show-expired subs-value ${showExpiredSubscription ? "checked" : ""}`}
                  type="checkbox"
                  id="show-expired"
                  checked={showExpiredSubscription}
                  onChange={() => {
                    setShowExpiredSubscription((prevVal) => !prevVal);
                    setCurrentPage(1);
                  }}
                />
                <label
                  className="show-expired subs-label ms-2"
                  htmlFor="show-expired"
                >
                  SHOW EXPIRED SUBSCRIPTIONS
                </label>
              </div>
            </div>
          )}
        </div>

        {isClever ? (
          CleverTable()
        ) : (
          <Table data={data} columns={columns} modalClass="invert-colors" />
        )}
        {isOrder && !userContext.userWithSubscription && !isClever && (
          <div className="no-table-data-message p-2">
            You don't have any active subscriptions. An order form is in
            progress.
          </div>
        )}
        {!userContext.userWithSubscription && !isOrderPending && !isClever && (
          <div className="no-table-data-message p-2">
            You don't have any active subscriptions
          </div>
        )}
        <AdminStepTwo
          text="Buy more Classroom or School Plans"
          to="/adm/checkout"
          toRenew={renewalsArray}
          linkText={`${isClever ? "Buy" : "Click"} Here`}
          linkClassName={`${isClever ? "btn btn-table-moderate-green" : ""}`}
        />
        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
};

export default ManageSubscription;
