import React, { useState } from "react";

interface ButtonMenuMobileProp {
  consumerDomain: string;
}

const ButtonMenuMobile = ({ consumerDomain }: ButtonMenuMobileProp) => {
  const DIRECTIONS = {
    home: consumerDomain + "/school",
    features: consumerDomain + "/school/features",
    "how-it-works": consumerDomain + "/school/how-it-works",
    "try-it-free": "/try-it-free",
    purchase: consumerDomain + "/school/purchase",
    redeem: "/gift/redeem/manual",
    questions: consumerDomain + "/school/questions",
    login: "/user",
  };
  const handleRedirection = (direction: string) => {
    window.location.replace(DIRECTIONS[direction]);
  };
  return (
    <div className="button-menu-mobile mx-auto ">
      <select
        id="edu_main_nav"
        className="menu-select"
        onChange={(event) => handleRedirection(event.target.value)}
      >
        <option value="home">Home </option>
        <option value="features">Features </option>
        <option value="how-it-works">How it Works </option>
        <option value="try-it-free">Try it free </option>
        <option value="purchase">Purchase </option>
        <option value="redeem">Redeem </option>
        <option value="questions">Questions </option>
        <option value="login">Sign In</option>
      </select>
    </div>
  );
};

export default ButtonMenuMobile;
