import cleverLoginErrorFull from "@images/errors/skybrary-clever-error-fullres.png";

interface ErrorMessageProps {
  children: React.ReactNode;
  isCleverLoginError?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  isCleverLoginError,
}) => {
  return (
    <div className="d-flex flex-column">
      <div>
        {isCleverLoginError ? (
          <img
            className="clever-login-error-image"
            src={cleverLoginErrorFull}
            alt="clever login error image"
          />
        ) : (
          <img
            className="clever-logo-image"
            src="/images/pages/clever/logo.svg"
            alt="Logo clever"
          />
        )}
      </div>
      <div className="mt-4 mx-auto clever-error-box">{children}</div>
    </div>
  );
};

export default ErrorMessage;
