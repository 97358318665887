import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import React, { useContext, useEffect, useState } from "react";
import AdminStepTwo from "../StepTwoAdmin";
import ManageTeacherModal from "../../AdminModals/Add-EditTeacher";
import UploadTeachersModal from "../../../Common/Modals/UploadCsvModal";
import ModalWindow from "../../../Common/ModalWindow";
import Table from "../../../Common/Table";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import TextSuccess from "../../../../TextSuccess";
import {
  getTeachers,
  getTeacherUploadStatus,
  cancelMigration,
} from "../../../../../api/rifService";
import UserContext from "../../../../../context/user";
import DeleteTeacher from "../../AdminModals/DeleteTeacher";
import TextError from "../../../../TextError";
import { Link } from "react-router-dom";
import rifLogo from "../../../../../images/rif-logo.png";
import UploadingTeachersModal from "../../../Common/Modals/UploadingCsvFile";
import CleverContext from '../../../../../context/clever'

let controller = new AbortController();

const ManageTeachers = () => {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  const collapseTextField = ["admin", cleverContext.isCleverUser() ? "manage_clever_teacher_help_text" : "manage_teacher_help_text"];

  let mappedTableData: any[] = [];

  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    document.title = "Manage My Teachers | Reading Is Fundamental";
  }, []);

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  const [teacherAdded, setTeacherAdded] = useState(false);
  const [teacherUpdated, setTeacherUpdated] = useState(false);
  const [teacherAssigned, setTeacherAssigned] = useState({
    status: false,
    message: "",
  });
  let allTeachers: any = [];
  const [teacherListUpdated, setTeacherListUpdated] = useState(false);
  const [teacherDeleted, setTeacherDeleted] = useState(false);
  const [uploadedTeachers, setUploadedTeachers] = useState({
    status: false,
    message: "",
  });
  const [cancelMigrationError, setCancelMigrationError] = useState(false);
  const [cancelMigrationSuccess, setCancelMigrationSuccess] = useState(false);

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const mapTableData = (allTeachersResponse: any) => {
    let counter = 0;

    allTeachersResponse.forEach((teacher: any) => {
      allTeachers.push({
        ...teacher,
      });
    });

    const checkMigrationStatus = (teacherId: number) => {
      let response = false;
      for (
        let index = 0;
        index < userContext.teachers.data.migration_requests.length;
        index++
      ) {
        if (userContext.teachers.data.migration_requests[index] === teacherId) {
          response = true;
        }
      }
      return response;
    };

    mappedTableData = allTeachers.map((teacher: any) => {
      counter++;
      const toMigrate = checkMigrationStatus(teacher.userId);

      return {
        col1: (
          <div className={`${toMigrate ? "teacher-to-migrate" : ""}`}>
            {counter}
          </div>
        ),
        col2: (
          <div className={`${toMigrate ? "teacher-to-migrate" : ""}`}>
            {teacher.edu.firstName}
          </div>
        ),
        col3: (
          <div className={`${toMigrate ? "teacher-to-migrate" : ""}`}>
            {teacher.edu.lastName}
          </div>
        ),
        col4: (
          <div className={`${toMigrate ? "teacher-to-migrate" : ""}`}>
            {teacher.emailAddress}
          </div>
        ),
        col5: (
          <>
            {!toMigrate ? (
                <>
                  {counter == 1 && (
                    <button
                      type="button"
                      className="btn btn-table-di-serria edit-teacher-button"
                      onClick={() =>
                        handleModal('edit-admin-teacher-modal', 0, {
                          fN: teacher.edu.firstName,
                          lN: teacher.edu.lastName,
                          email: teacher.emailAddress,
                          id: teacher.edu.eduUserId,
                        })
                      }
                    >
                      EDIT
                    </button>
                  )}
                  {counter > 1 && (
                    <>
                      <button
                        type="button"
                        className="btn btn-table-di-serria edit-teacher-button"
                        onClick={() =>
                          handleModal('edit-teacher-modal', 0, {
                            fN: teacher.edu.firstName,
                            lN: teacher.edu.lastName,
                            email: teacher.emailAddress,
                            id: teacher.edu.eduUserId,
                          })
                        }
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="delete-teacher-button btn btn-mojo-modal"
                        onClick={() => {
                          handleModal(
                            'delete-teacher-modal',
                            teacher.edu.eduUserId,
                            {}
                          )
                        }}
                      />
                    </>
                  )}
                </>
              )
              :
              (
                <div className="teacher-to-migrate text-center">
                  Pending
                  <button
                    disabled={buttonDisabled}
                    type="button"
                    className="btn btn-table-mojo cancel-migration-button btn-spinner-indicator"
                    onClick={() => handleCancelMigration(teacher.userId)}
                  >
                    CANCEL
                  </button>
                </div>
              )}
          </>
        ),
        col6: "",
      };
    });
    userContext.setFormProgress(0);
    setDataTable(mappedTableData);
  };

  useEffect(() => {
    if (cleverContext.isCleverUser()) {
      mapTableData([]);
    } else if (userContext.teachers) {
      mapTableData(userContext.teachers.data.teachers);
    }
  }, [buttonDisabled, userContext.teachers]);

  const handleCancelMigration = async (teacherId: any) => {
    userContext.setFormProgress(50);
    setButtonDisabled(true);
    setTeacherUpdated(false);
    setTeacherAdded(false);
    setTeacherDeleted(false);
    setTeacherAssigned({ status: false, message: "" });
    setUploadedTeachers({ status: false, message: "" });
    setCancelMigrationError(false);
    setCancelMigrationSuccess(false);
    setCancelMigrationError(false);
    const response = await cancelMigration(teacherId, requestConfig);
    if (response) {
      setCancelMigrationSuccess(true);
      handleTeacher("", "");
    } else {
      setCancelMigrationError(true);
      setButtonDisabled(false);
    }
  };

  //Function to retrieve data from api and call mapTableData

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  const [dataTable, setDataTable] = useState([]);

  const handleTeacher = (type: string, message: string) => {
    userContext.setFormProgress(70);
    getTeachers(requestConfig, "edu").then((response: any) => {
      userContext.saveTeachers(
        response,
        userContext.userData.edu.user.relatedAccountId,
        "admin"
      );

      userContext.setFormProgress(100);
      setButtonDisabled(false);
      handleClose();
      if (type === "add-teacher") {
        setTeacherAdded(true);
        setTeacherListUpdated(!teacherListUpdated);
      } else if (type === "edit-teacher") {
        setTeacherUpdated(true);
      } else if (type === "delete-teacher") {
        setTeacherDeleted(true);
      } else if (type === "teacher-assigned") {
        setTeacherAssigned({ status: true, message: message });
      } else if (type === "upload-teacher") {
        setUploadedTeachers({ status: true, message: message });
      }
    });
  };

  const handleUploadState = (successMessage: string) => {
    handleTeacher("upload-teacher", successMessage);
  };

  const handleUploadTeachers = (props: any) => {
    if (props.batchId) {
      setModal({
        show: true,
        class: "uploading-teacher-modal",
        onClick: handleClose,
        header: (
          <div className="logo">
            <Link to="/">
              <img src={rifLogo} alt="" width={118} />
            </Link>
          </div>
        ),
        body: (
          <UploadingTeachersModal
            onClick={handleClose}
            onSubmit={handleUploadState}
            schoolId={userContext.subscriptions[0].schoolId}
            batchId={props.batchId}
            from={props.from}
            csv="teachers"
          />
        ),
      });
    } else {
      handleUploadState(props.error);
    }
  };

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = (modal: string, id: number, teacherData: any) => {
    setTeacherUpdated(false);
    setTeacherAdded(false);
    setTeacherDeleted(false);
    setTeacherAssigned({ status: false, message: "" });
    setUploadedTeachers({ status: false, message: "" });
    setCancelMigrationError(false);
    setCancelMigrationSuccess(false);
    switch (modal) {
      case "add-teacher-modal":
        setModal({
          show: true,
          class: "add-teacher-modal",
          onClick: handleClose,
          header: <h1>Add Teacher</h1>,
          body: (
            <ManageTeacherModal
              modalType="add"
              onClick={handleClose}
              onSubmit={handleTeacher}
              teacherData={""}
              alsoAdmin={false}
            />
          ),
        });
        break;
      case "edit-teacher-modal":
        setModal({
          show: true,
          class: "add-teacher-modal",
          onClick: handleClose,
          header: <h1>Edit Teacher</h1>,
          body: (
            <ManageTeacherModal
              modalType="edit"
              onClick={handleClose}
              onSubmit={handleTeacher}
              teacherData={teacherData}
              alsoAdmin={false}
            />
          ),
        });
        break;
      case "edit-admin-teacher-modal":
        setModal({
          show: true,
          class: "add-teacher-modal",
          onClick: handleClose,
          header: <h1>Edit Teacher</h1>,
          body: (
            <ManageTeacherModal
              modalType="edit"
              onClick={handleClose}
              onSubmit={handleTeacher}
              teacherData={teacherData}
              alsoAdmin={true}
            />
          ),
        });
        break;
      case "upload-teacher-modal":
        setModal({
          show: true,
          class: "upload-teacher-modal",
          onClick: handleClose,
          header: <></>,
          body: (
            <UploadTeachersModal
              onClick={handleClose}
              onSubmit={handleUploadTeachers}
              schoolId={userContext.subscriptions[0].schoolId}
              csv="teachers"
            />
          ),
        });
        break;
      case "delete-teacher-modal":
        setModal({
          show: true,
          class: "delete-teacher-modal",
          onClick: handleClose,
          header: <></>,
          body: (
            <DeleteTeacher
              onSubmit={handleTeacher}
              onClick={handleClose}
              id={id}
              from="manage-teachers"
            />
          ),
        });
        break;
    }
  };

  const items = [
    {
      step: 1,
      text: "Admin Dashboard",
      link: "/adm/dashboard",
    },
    {
      step: 2,
      text: "Manage My Teachers",
      link: "/adm/school/teacher",
    },
  ];

  const columns = React.useMemo(() => [
    {
      Header: "#",
      accessor: "col1",
      width: 60,
    },
    {
      Header: "First Name",
      accessor: "col2",
      width: 146.27,
      customStyles: {
        textAlign: "left",
        paddingLeft: "0.9375rem"
      }
    },
    {
      Header: "Last Name",
      accessor: "col3",
      width: 143.52,
    },
    {
      Header: "Email",
      accessor: "col4",
      width: 452.59,
    },
    {
      Header: "",
      accessor: "col5",
      width: 120,
    },
  ], []);

  const data = React.useMemo(() => dataTable, [dataTable]);

  return (
    <Dashboard rol="admin">
      <div className="manage-teachers">
        <Steeper steps={items} activeStep={2} steeperType="dashboard" />
        <CollapseText
          dashboardField={collapseTextField}
          bodyImg=""
          title="Manage My Teachers"
        />
        {teacherUpdated && (
          <div className="text-success-dashboards">
            <TextSuccess text={"Teacher Updated"} />
          </div>
        )}
        {uploadedTeachers.status && (
          <div className="text-success-dashboards">
            <TextSuccess text={uploadedTeachers.message} />
          </div>
        )}
        {teacherAdded && (
          <div className="text-success-dashboards">
            <TextSuccess text={"Teacher Added"} />
          </div>
        )}
        {teacherDeleted && (
          <div className="text-success-dashboards">
            <TextSuccess text="The teacher was deleted." />
          </div>
        )}
        {cancelMigrationSuccess && (
          <div className="text-success-dashboards">
            <TextSuccess text="Your pending migration request has been cancelled." />
          </div>
        )}
        {teacherAssigned.status && (
          <div className="pb-3">
            <TextError wrapper="div" text={teacherAssigned.message} />
          </div>
        )}
        {cancelMigrationError && (
          <div className="text-success-dashboards">
            <TextError
              wrapper="div"
              text={
                "There was an error, We were not able to cancel the migration, please try again"
              }
            />
          </div>
        )}
        <div className="main-header user-classroster admin-table">
          <span>All Teachers</span>
          <span className="manage-teachers-button-container">
            <button
              type="button"
              className="btn-red-damask admin-table-button"
              onClick={() => handleModal("upload-teacher-modal", 0, "")}
            >
              UPLOAD TEACHERS
            </button>
            <button
              type="button"
              className="btn-red-damask admin-table-button"
              onClick={() => handleModal("add-teacher-modal", 0, "")}
            >
              ADD TEACHER
            </button>
          </span>
        </div>
        <Table data={data} columns={columns} modalClass={undefined} />
        <AdminStepTwo
          text="Step 2: Assign a Teacher to a Subscription."
          to="/adm/school"
          linkText="Manage My Subscriptions."
        />

        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
};

export default ManageTeachers;
