import BasicInfo from "../../../../Forms/purchase/BasicInfo";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../../TextError";

const schemaValidate = Yup.object({
  school: Yup.string().required("School field is required.").max(128),
  street: Yup.string().required("Street field is required.").max(128),
  city: Yup.string().required("City field is required.").max(128),
  country: Yup.string().required("Country field is required."),
  state: Yup.string().when(["country"], {
    is: (value: string) => value && value.length > 0,
    then: Yup.string().required("State field is required."),
    otherwise: Yup.string(),
  }),
  zip: Yup.string()
    .required("Zip Code/Postal Code field is required.")
    .max(128),
  schoolPhone: Yup.string()
    .required("School Phone field is required.")
    .max(128),
});

const SchoolManagementForm = (props: {
  initialValues: {
    name: string;
    street: string;
    city: string;
    countryId: number;
    schoolId: string;
    zip: string;
    phone: string;
    typeId: number;
    isTitleOne: number;
    stateId: number;
  };
  onSubmit: any;
  buttonState: any;
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          school: props.initialValues.name,
          street: props.initialValues.street,
          city: props.initialValues.city,
          country: props.initialValues.countryId,
          state: props.initialValues.stateId,
          zip: props.initialValues.zip,
          schoolPhone: props.initialValues.phone,
          schoolType: props.initialValues.typeId,
          isTitleOne: props.initialValues.isTitleOne,
        }}
        validationSchema={schemaValidate}
        onSubmit={props.onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values }) => (
          <Form>
            {Object.keys(errors).length > 0 ? (
              <div className="errors">
                <div className="col-1 p-0 error-logo" />
                <ul className="p-0 errors-list">
                  <ErrorMessage
                    name="school"
                    component={() => (
                      <TextError wrapper="li" text={errors.school} />
                    )}
                  />
                  <ErrorMessage
                    name="city"
                    component={() => (
                      <TextError wrapper="li" text={errors.city} />
                    )}
                  />
                  <ErrorMessage
                    name="country"
                    component={() => (
                      <TextError wrapper="li" text={errors.country} />
                    )}
                  />
                  <ErrorMessage
                    name="state"
                    component={() => (
                      <TextError wrapper="li" text={errors.state} />
                    )}
                  />
                  <ErrorMessage
                    name="zip"
                    component={() => (
                      <TextError wrapper="li" text={errors.zip} />
                    )}
                  />
                  <ErrorMessage
                    name="schoolPhone"
                    component={() => (
                      <TextError wrapper="li" text={errors.schoolPhone} />
                    )}
                  />
                  <ErrorMessage
                    name="schoolType"
                    component={() => (
                      <TextError wrapper="li" text={errors.schoolType} />
                    )}
                  />
                  <ErrorMessage
                    name="isTitleOne"
                    component={() => (
                      <TextError wrapper="li" text={errors.isTitleOne} />
                    )}
                  />
                </ul>
              </div>
            ) : null}

            <BasicInfo
              errors={errors}
              isValid={"isValid"}
              dirty={"dirty"}
              classes={"py-3 form-item"}
              values={props.initialValues}
            />
            <div className="my-3 text-center">
              <button
                type="submit"
                className="school-button btn btn-table-di-serria btn-spinner-indicator"
                disabled={props.buttonState}
              >
                SAVE
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SchoolManagementForm;
