import React, { useContext, useEffect, useState } from "react";
import { FormPurchaseContext } from "../../../../context/Forms/purchaseContext";
import UserContext from "../../../../context/user";
import Table from "../../Common/Table";
import CleverContext from "../../../../context/clever";
import { isCleverTheme } from "../../../../utils/helpers";
import RemoveRenewalButton from "../../../../components/Buttons/RemoveRenewalButton";
import { Spinner } from "react-bootstrap";

interface RenewalsRowData {
  name: string;
  grade?: any; // change when grade is available
  price: string;
  teacherName?: string;
  removeBtn: JSX.Element;
}

const Renewals = (props: { remove: boolean }) => {
  const userContext: any = useContext(UserContext);
  const cleverContext: any = useContext(CleverContext);

  let mappedTableData: any = [];

  // Remove once the global variable is added
  const isCleverSession = isCleverTheme(userContext, cleverContext);

  const renewalsIds: string[] = useContext(FormPurchaseContext).renewals;

  useEffect(() => {
    if (userContext.subscriptions.length > 0 || renewalsIds.length > 0) {
      mapTableData();
    }
  }, [renewalsIds, userContext, cleverContext]);

  const [dataTable, setDataTable] = useState([]);
  const data = React.useMemo(() => dataTable, [dataTable]);

  const findSubscription = (
    id: string,
    isClever: boolean,
    subscriptions: any[],
  ): any | null => {
    if (!subscriptions) {
      return null;
    }
    return isClever
      ? subscriptions.find((sub: any) => id === sub.subId.toString())
      : subscriptions.find(
          (sub: any) => id === sub.rrkSubscriptionEduId.toString(),
        );
  };

  const getSubscriptionName = (id: string): string => {
    const subscriptions = isCleverSession
      ? cleverContext.getRenewSubscriptions()
      : userContext.subscriptions;
    if (!subscriptions) {
      return "-";
    }

    const sub = findSubscription(id, isCleverSession, subscriptions);
    if (!sub) {
      return "-";
    }

    return isCleverSession ? sub.eduSubscription.class_name : sub.className;
  };

  const getTeacherName = (id: string): string => {
    const subscriptions = isCleverSession
      ? cleverContext.getRenewSubscriptions()
      : userContext.subscriptions;
    if (!subscriptions) {
      return "-";
    }

    const sub = findSubscription(id, isCleverSession, subscriptions);
    if (!sub) {
      return "-";
    }

    const teacherId = sub.eduSubscription.rrk_teacher_id;
    if (!teacherId) {
      return "-";
    }

    const teacher = cleverContext
      .getCleverTeachers()
      ?.find((teacher: any) => teacher.edu.eduUserId === teacherId);
    return teacher ? `${teacher.edu.firstName} ${teacher.edu.lastName}` : "-";
  };

  const mapTableData = () => {
    mappedTableData = renewalsIds.map((id: string): RenewalsRowData => {
      return !isCleverSession
        ? {
            name: getSubscriptionName(id),
            price: "$179",
            removeBtn: (
              <RemoveRenewalButton
                isClever={false}
                id={id}
                subscriptionIds={renewalsIds}
              />
            ),
          }
        : {
            name: getSubscriptionName(id),
            grade: "--",
            teacherName: getTeacherName(id),
            price: "$179",
            removeBtn: (
              <RemoveRenewalButton
                isClever
                id={id}
                subscriptionIds={renewalsIds}
              />
            ),
          };
    });
    setDataTable(mappedTableData);
  };

  const eduColumns = React.useMemo(
    () => [
      {
        accessor: "name",
        width: 270,
      },
      {
        accessor: "price",
        width: 100,
      },
      {
        accessor: "removeBtn",
        width: 75,
      },
    ],
    [],
  );

  const cleverColumns = React.useMemo(
    () => [
      {
        accessor: "name",
        width: 214,
      },
      {
        accessor: "grade",
        width: 86,
      },
      {
        accessor: "teacherName",
        width: 244,
      },
      {
        accessor: "price",
        width: 125,
      },
      {
        accessor: "removeBtn",
        width: 75,
      },
    ],
    [],
  );

  return (
    <div className={`renewal-tables ${props.remove ? "remove" : "no-remove"}`}>
      <h1 className="renewal-title">Renewals</h1>
      {data.length > 0 ? (
        <div className="table-renewal-wrapper">
          <Table
            data={data}
            columns={isCleverSession ? cleverColumns : eduColumns}
            modalClass={undefined}
          />
        </div>
      ) : (
        <Spinner animation="border" role="status" />
      )}
    </div>
  );
};

export default Renewals;
