import React, { useContext, useEffect, useState } from 'react'
import UserContext from "../../../../../../context/user";
import AdminInfoItem from "./AdminInfoItem";
import { utcToZonedTime } from "date-fns-tz";
import { getYear, getMonth, format } from 'date-fns'
import CleverContext from '../../../../../../context/clever'
import { isCleverTheme } from "../../../../../../utils/helpers";
import { getSummaryOfCleverDistrict } from '../../../../../../api/rifService'
import { EduSubscription } from '../../../../../../types/subscriptionTypes';

const RightColumnInfo = (props: { countStudents: any }) => {
  const userContext: any = useContext(UserContext);
  const cleverContext: any = useContext(CleverContext);
  const [totalSubscriptions, setTotalSubscriptions] = useState(userContext.subscriptions.length);
  const [unassignedTeachers, setUnassignedTeachers] = useState(0);
  const [earliestRenewalDate, setEarliestRenewalDate] = useState('');
  const [renewalYear, setRenewalYear] = useState(0);
  const [renewalMonth, setRenewalMonth] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalBooksRead, setTotalBooksRead] = useState(0);
  const [totalTimeReading, setTotalTimeReading] = useState(0);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  function setSummaryReport (schoolReports:any) {
    let tempBooksRead = 0
    let temptotalTimeReading = 0
    let temptotalStudents = 0
    for (let x = 0; x < schoolReports.length; x++) {
      tempBooksRead += schoolReports[x].booksRead
      temptotalTimeReading += schoolReports[x].totalReadingTime
      temptotalStudents += schoolReports[x].numberOfStudents
    }
    setTotalBooksRead(tempBooksRead)
    setTotalStudents(temptotalStudents)
    setTotalTimeReading(temptotalTimeReading = Math.ceil(temptotalTimeReading / 3600))
  }

  function setSummaryReportForCleverDistrict () {
    getSummaryOfCleverDistrict(requestConfig, null).then((summaryReport) => {
        let timeRead = summaryReport.data.booksHistory.totalTimeReading
        const timestamp = summaryReport.data.subscriptions.earliestExpirationDate
        // Convert timestamp in miliseconds
        let date = null
        if(timestamp) {
          date = new Date(timestamp * 1000)
        }

        setTotalBooksRead(summaryReport.data.booksHistory.amountOfUniqueBooksRead)
        setTotalStudents(summaryReport.data.students.amountOfStudents)
        setTotalTimeReading(timeRead = Math.ceil(timeRead / 3600))
        setTotalSubscriptions(summaryReport.data.subscriptions.amountOfSubs)
        setUnassignedTeachers(summaryReport.data.subscriptions.freeSubs)

        // Date format to month, day, year: EX: 04/02/1993
        if(date) {
          setEarliestRenewalDate(format(date, 'MM/dd/yyyy'))
          const renewalDate = utcToZonedTime(date, 'UTC')
          const actualDate = new Date()
          setRenewalYear(getYear(renewalDate) - getYear(actualDate))
          setRenewalMonth(getMonth(renewalDate) - getMonth(actualDate))
        }
      }
    )
  }

  useEffect(() => {
    let count = 0
    let date = Infinity
    if (!cleverContext.isCleverUser()) {
      count = userContext.subscriptions.filter((sub: EduSubscription) => sub.teacherId === null).length
      for (let y = 0; y < userContext.subscriptions.length; y++) {
        if (Boolean(userContext.subscriptions[y].expirationDate) && (userContext.subscriptions[y].expirationDate < date)) {
          date = userContext.subscriptions[y].expirationDate
        }
      }

      if (date != Infinity && date !== null) {
        setEarliestRenewalDate(format(new Date(date), 'MM/dd/yyyy'))
        const renewalDate = utcToZonedTime(date, "UTC");
        const actualDate = new Date();

        setRenewalYear(getYear(renewalDate) - getYear(actualDate));
        setRenewalMonth(getMonth(renewalDate) - getMonth(actualDate));
      }
    }
  }, [userContext.subscriptions])

  useEffect(() => {
    props.countStudents(totalStudents);

    if (cleverContext.isCleverUser()) {
      setSummaryReportForCleverDistrict()
    } else {
      setSummaryReport(userContext.schoolReports)
    }
  }, [])

  return (
    <div className={isCleverTheme(userContext, cleverContext) ? 'table-info-wrapper' : 'table-classic'}>
      {!isCleverTheme(userContext, cleverContext) ? (
        <>
          <div className="row tutorial m-0">
            <div className="col">
              <div className="main-tutorial">
                <a
                  href="https://cf.rrkidz.com/files/downloads/EDU_Tutorial_GettingStartedAdmin.pdf"
                  className="download"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Admin Dashboard Tutorial
                </a>
              </div>
            </div>
          </div>
          <div className="row extra_info m-0">
            <AdminInfoItem
              text={"Earliest Subscription renewal date:"}
              classes={`date ${earliestRenewalDate && renewalYear < 1 && renewalMonth < 3 ? "close-to" : ""}`}
              description={""}
            >
              {earliestRenewalDate ? (
                <span>
                  {earliestRenewalDate}
                </span>
              ) : (
                <> - </>
              )}
            </AdminInfoItem>
            <AdminInfoItem
              text={"Total Students (all classes):"}
              classes={"totals"}
              description={"description"}
            >
              {totalStudents}
            </AdminInfoItem>
            <AdminInfoItem
              text={"Total Books Read:"}
              classes={"totals"}
              description={"description"}
            >
              {totalBooksRead}
            </AdminInfoItem>
            <AdminInfoItem
              text={"Total Time Reading (hrs):"}
              classes={"totals"}
              description={"description"}
            >
              {totalTimeReading}
            </AdminInfoItem>
            <AdminInfoItem
              text={"Total Subscriptions:"}
              classes={"totals"}
              description={"description"}
            >
              {totalSubscriptions}
            </AdminInfoItem>
            <AdminInfoItem
              text={"Total Unassigned Subscriptions:"}
              classes={"totals"}
              description={"description"}
            >
              {unassignedTeachers}
            </AdminInfoItem>
          </div>
        </>
      ) : (
        <>
          <h1 className="admin-user"></h1>
          <div className="row">
            <div className="col buy-here">
              <p className="message">
                Buy Classroom or School Plans
              </p>
              <a
                href={"/adm/checkout"}
                className="btn button btn-moderate-green"
              >
                BUY HERE
              </a>
            </div>
          </div>
          <div className="row extra_info m-0">
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 5V1M14 5V1M6.01 8H6M6.01 11H6M6.01 14H6M10.01 11H10M10.01 8H10M14.01 11H14M10.01 14H10M14.01 8H14M4 19H16C17.6569 19 19 17.6569 19 16V6C19 4.34315 17.6569 3 16 3H4C2.34315 3 1 4.34315 1 6V16C1 17.6569 2.34315 19 4 19Z"
                      stroke="#363538" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Earliest Subscription Renewal Date:</span>
                </>
              }
              classes={`date ${earliestRenewalDate && renewalYear < 1 && renewalMonth < 3 ? "close-to" : ""}`}
              description={""}
            >
              {earliestRenewalDate ? (
                <span>
                  {earliestRenewalDate}
                </span>
              ) : (
                <> - </>
              )}
            </AdminInfoItem>
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.8086 5.15899L17.4296 2.53607L15.1272 0.196864C15.0014 0.0694813 14.8373 0 14.6678 0H0.667214C0.300793 0 0 0.318456 0 0.706393V23.2936C0 23.6815 0.300793 24 0.667214 24H19.3328C19.6992 24 20 23.6815 20 23.2936V5.65115C19.9945 5.46586 19.9234 5.29216 19.8086 5.15899ZM16.4835 3.53776L18.4031 5.65115H14.6623V1.68492L16.4835 3.53776ZM18.6601 22.593H1.32349V1.41279H13.3279V6.35175C13.3279 6.73969 13.6287 7.05814 13.9951 7.05814H18.6601V22.593Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.9912 18.3546H3.99234C3.62592 18.3546 3.32513 18.6731 3.32513 19.061C3.32513 19.449 3.62592 19.7674 3.99234 19.7674H15.9912C16.3577 19.7674 16.6585 19.449 16.6585 19.061C16.6585 18.6731 16.3631 18.3546 15.9912 18.3546Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.9912 14.1221H3.99234C3.62592 14.1221 3.32513 14.4405 3.32513 14.8285C3.32513 15.2164 3.62592 15.5349 3.99234 15.5349H15.9912C16.3577 15.5349 16.6585 15.2164 16.6585 14.8285C16.6585 14.4405 16.3631 14.1221 15.9912 14.1221Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3.32513 10.5901C3.32513 10.978 3.62592 11.2965 3.99234 11.2965H15.9912C16.3577 11.2965 16.6585 10.978 16.6585 10.5901C16.6585 10.2022 16.3577 9.88371 15.9912 9.88371H3.99234C3.62045 9.88371 3.32513 10.2022 3.32513 10.5901Z"
                          fill="#2E323F"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Total Subscriptions:</span>
                </>
              }
              classes={"totals"}
              description={"description"}
            >
              {totalSubscriptions}
            </AdminInfoItem>
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 20 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.8086 5.15899L17.4296 2.53607L15.1272 0.196864C15.0014 0.0694813 14.8373 0 14.6678 0H0.667214C0.300793 0 0 0.318456 0 0.706393V23.2936C0 23.6815 0.300793 24 0.667214 24H19.3328C19.6992 24 20 23.6815 20 23.2936V5.65115C19.9945 5.46586 19.9234 5.29216 19.8086 5.15899ZM16.4835 3.53776L18.4031 5.65115H14.6623V1.68492L16.4835 3.53776ZM18.6601 22.593H1.32349V1.41279H13.3279V6.35175C13.3279 6.73969 13.6287 7.05814 13.9951 7.05814H18.6601V22.593Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.9912 18.3546H3.99234C3.62592 18.3546 3.32513 18.6731 3.32513 19.061C3.32513 19.449 3.62592 19.7674 3.99234 19.7674H15.9912C16.3577 19.7674 16.6585 19.449 16.6585 19.061C16.6585 18.6731 16.3631 18.3546 15.9912 18.3546Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.9912 14.1221H3.99234C3.62592 14.1221 3.32513 14.4405 3.32513 14.8285C3.32513 15.2164 3.62592 15.5349 3.99234 15.5349H15.9912C16.3577 15.5349 16.6585 15.2164 16.6585 14.8285C16.6585 14.4405 16.3631 14.1221 15.9912 14.1221Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3.32513 10.5901C3.32513 10.978 3.62592 11.2965 3.99234 11.2965H15.9912C16.3577 11.2965 16.6585 10.978 16.6585 10.5901C16.6585 10.2022 16.3577 9.88371 15.9912 9.88371H3.99234C3.62045 9.88371 3.32513 10.2022 3.32513 10.5901Z"
                          fill="#2E323F"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Total Unassigned Subscriptions:</span>
                </>
              }
              classes={"totals"}
              description={""}
              subText={
                <>
                  <span className={"align-self-center subtext-no-icon"}>Assign subscriptions through Clever.</span>
                </>
              }
            >
              {unassignedTeachers}
            </AdminInfoItem>
          </div>
          <div className="row extra_info m-0 mt-3">
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.1378 13.1871C8.52269 13.1871 5.57682 10.2311 5.57682 6.59354C5.57682 2.95593 8.52269 0 12.1378 0C15.7529 0 18.6987 2.95593 18.6987 6.59354C18.6987 10.2311 15.7529 13.1871 12.1378 13.1871ZM12.1378 1.43389C9.40186 1.43389 7.17769 3.74927 7.17769 6.59354C7.17769 9.43781 9.40186 11.7532 12.1378 11.7532C14.8737 11.7532 17.0979 9.43781 17.0979 6.59354C17.0979 3.74927 14.8737 1.43389 12.1378 1.43389Z"
                          fill="#2E323F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M23.1996 24H0.800437C0.360853 24 0 23.6768 0 23.2831V20.5093C0 20.3859 2.86714 15.9843 12 15.9843C21.1329 15.9843 24 20.3976 24 20.5211V23.2831C24 23.6768 23.6391 24 23.1996 24ZM1.60087 22.5661H22.3991V20.7385C21.8611 20.0921 19.1843 17.4123 12 17.4123C4.82231 17.4123 2.14543 20.0803 1.60087 20.7267V22.5661Z"
                          fill="#2E323F"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Total Number of Students (all classes):</span>
                </>
              }
              classes={"totals"}
              description={"description"}
            >
              {totalStudents}
            </AdminInfoItem>
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 4.00003V2.00003C1 1.44775 1.44772 1.00003 2 1.00003H4C4.55228 1.00003 5 1.44775 5 2.00003V4.00003M1 4.00003H5M1 4.00003V16M5 4.00003V16M1 16V18C1 18.5523 1.44772 19 2 19H4C4.55228 19 5 18.5523 5 18V16M1 16H5M8 4.00003V2.00003C8 1.44775 8.44772 1.00003 9 1.00003H11C11.5523 1.00003 12 1.44775 12 2.00003V4.00003M8 4.00003H12M8 4.00003V16M12 4.00003V16M8 16V18C8 18.5523 8.44772 19 9 19H11C11.5523 19 12 18.5523 12 18V16M8 16H12M14.9835 4.44112L14.6345 2.47181C14.5381 1.928 14.9008 1.40903 15.4446 1.31265L17.4139 0.963632C17.9577 0.867255 18.4767 1.22997 18.5731 1.77378L18.9221 3.74309M14.9835 4.44112L18.9221 3.74309M14.9835 4.44112L17.0776 16.257M18.9221 3.74309L21.0162 15.559M17.0776 16.257L17.4266 18.2263C17.523 18.7701 18.0419 19.1328 18.5858 19.0365L20.5551 18.6874C21.0989 18.5911 21.4616 18.0721 21.3652 17.5283L21.0162 15.559M17.0776 16.257L21.0162 15.559"
                      stroke="#363538" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Total Books Read:</span>
                </>
              }
              classes={"totals"}
              description={"description"}
            >
              <span className={"ms-2 align-self-center"}>{totalBooksRead}</span>
            </AdminInfoItem>
            <AdminInfoItem
              text={
                <>
                  <svg className={"align-self-center"} width="18" height="18" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 4C4.58172 4 1 7.58172 1 12C1 16.4183 4.58172 20 9 20C13.4183 20 17 16.4183 17 12C17 7.58172 13.4183 4 9 4ZM9 4V1.5M9 1.5H6M9 1.5H12M9 8V12.382C9 12.7607 9.214 13.107 9.55279 13.2764L12 14.5"
                      stroke="#363538" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span className={"ms-2 align-self-center"}>Total Time Reading (hours):</span>
                </>
              }
              classes={"totals"}
              description={"description"}
            >
              {totalTimeReading}
            </AdminInfoItem>
          </div>
        </>
      )}
    </div>
  );
};

export default RightColumnInfo;
