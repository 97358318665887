import React from "react"

export const Tutorials = () => {
  return (
    <>
      <p className="description-text">We are excited for you to start using Skybrary, powered by Reading Is Fundamental.</p>
      <p className="description-text">This is your administrator dashboard. Here you can manage your subscriptions and view reports.</p>
      <p className="description-text">Please view our tutorials to set up your teachers, or feel free to explore the dashboard.</p>
    </>
  )
}

export const RenewalInstructions = () => {
  return (
    <>
      <p className="description-text">ATTENTION Account Holder!</p>
      <p className="description-text">
        It's time to RENEW one or more of your subscriptions.
      </p>
      <p className="description-text">
        Hurry, don't let your plans expire. It's quick & easy to renew!{" "}
      </p>
      <p className="description-text"> Here's how:</p>
      <p className="description-text"> 1. Go to MANAGE MY SUBSCRIPTIONS.</p>
      <p className="description-text">
        2. Click Renew next to the classroom(s) you'd like to renew.
      </p>
      <p className="description-text">
        (If you do not do this, you may end up adding a new classroom to your
        account, instead of renewing your existing one.){" "}
      </p>
      <p className="description-text"> 3. Click orange CHECKOUT button.</p>
      <p className="description-text">
        4. In the Order Detail screen, make sure your classroom appears below
        the Renewals heading.
      </p>
      <p className="description-text">
        5. You can then renew via a Credit Card or pay by Purchase Order or
        check.
      </p>
      <p className="description-text">
        To pay via PO or check, click Order Form to download our Order Form
        and follow the instructions.
      </p>
    </>
  )
}

export const DashboardInstructions = () => {
    return (
    <>
      <p className="description-text">
      This is your Administrator Dashboard!
      </p>
      <p className="description-text">-View subscription information</p>
      <p className="description-text">-Assign teachers to subscriptions</p>
      <p className="description-text">-Change account information</p>
      <p className="description-text">-Purchase additional subscriptions</p>
      <p className="description-text">
      There are two ways to get started depending on your role:
      </p>
      <p className="description-text">OPTION 1: If you are a TEACHER</p>
      <p className="description-text">
      1. Click the MANAGE MY SUBSCRIPTIONS tab to assign a subscription to
      your account (read instructions carefully).
      </p>
      <p className="description-text">
      2. Next, use the drop-down menu in the upper-right hand corner to go
      to your Teacher Dashboard.
      </p>
      <p className="description-text">
      OPTION 2: If you are an ADMIN, assign multiple subscriptions
      </p>
      <p className="description-text">
      1. Click MANAGE MY TEACHERS to add teachers to subscriptions
      purchased.
      </p>
      <p className="description-text">
      2. Next, click the MANAGE MY SUBSCRIPTIONS tab to assign
      subscriptions to each of your teachers (read instructions
      carefully).
      </p>
    </>
  )
}
