import { OPACITY_FOR_LOADING_STATE } from "@utils/constants";
import React from "react";

interface PaginationButtonProps {
  isClever?: boolean;
  handlePrev: () => void;
  handleNext: () => void;
  isLoading?: boolean;
  lastPage: number;
  page: number;
}

const PaginationButtons: React.FC<PaginationButtonProps> = ({
  isClever = true,
  handlePrev,
  handleNext,
  isLoading = false,
  lastPage,
  page,
}) => {
  let prevClassName = "visible";
  let nextClassName = "visible";

  if (page === 1) {
    prevClassName = "invisible";
  }

  if (lastPage === page) {
    nextClassName = "invisible";
  }

  if (isClever) {
    return (
      <div className="pagination">
        <button
          disabled={isLoading}
          className={prevClassName}
          onClick={handlePrev}
        >
          <svg
            opacity={isLoading ? OPACITY_FOR_LOADING_STATE : 1}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 6.34919V17.6508C19 19.9936 16.4347 21.4323 14.4357 20.2107L5.18884 14.5599C3.27464 13.3901 3.27464 10.61 5.18884 9.44018L14.4356 3.78934C16.4347 2.5677 19 4.0064 19 6.34919Z"
              fill="#184378"
              stroke="#184378"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <span className="page-number">{page}</span>
        <button
          disabled={isLoading}
          className={nextClassName}
          onClick={handleNext}
        >
          <svg
            opacity={isLoading ? OPACITY_FOR_LOADING_STATE : 1}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 6.34919V17.6508C5 19.9936 7.56529 21.4323 9.56435 20.2107L18.8112 14.5599C20.7254 13.3901 20.7254 10.61 18.8112 9.44018L9.56435 3.78934C7.56529 2.5677 5 4.0064 5 6.34919Z"
              fill="#184378"
              stroke="#184378"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  } else {
    return (
      <div className="pagination">
        <button
          disabled={isLoading}
          className={prevClassName}
          onClick={handlePrev}
        >
          {"<< Prev"}
        </button>
        <button
          disabled={isLoading}
          className={nextClassName}
          onClick={handleNext}
        >
          {"Next >>"}
        </button>
      </div>
    );
  }
};

export default PaginationButtons;
